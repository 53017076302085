import React, {useState, useMemo, useEffect} from 'react';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup  } from 'react-bootstrap';
import {Linking, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground,Alert} from 'react-native';
import Jump from 'react-reveal/Jump';
import Tada from 'react-reveal/Tada';
import {Flip} from 'react-reveal';
import logo from '../images/logo_useradventure.jpg';
import countryList from 'react-select-country-list'


const Modal1 = ({show, Quit, Nom, ChangeNom, Prenom, ChangePrenom, Mail, ChangeMail, sexe, ChangeGenre, value, changeHandler, startDate, ChangeDate, validCommand, disabledcolor}) => {

    const color1 = "#007F8B";
    const color2 = "#988EFA";
    const color3 = "#0b7482";
    const color4 = "#10b2c8";
   
    const optionSexe = [
        { value: '', label: 'Selectionner...' },
        { value: 1, label: 'Homme' },
        { value: 2, label: 'Femme' },
       // { value: 3, label: `Je m'identifie comme un homme` },
       // { value: 4, label: `Je m'identifie comme une femme` },
        { value: 5, label: `Je ne m'identifie à aucun genre` }
      ]

      const options = useMemo(() => countryList().getData(), [])

      

    return (
        <>
        <Modal style={{marginTop: 100}} fullscreen={true} show={show}  onHide={Quit}>
        <Modal.Header style={{background: `linear-gradient(to right,  ${color3} 0%,${color4} 100%)`}} closeButton closeVariant="white">
          <Modal.Title>
        <Text style={{color:'white',fontWeight:'bold', fontSize:22, marginHorizontal:20}}>LANCER UNE ETUDE</Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{background: `white`}}>
        <Text style={{fontWeight:'bold', fontSize:22, marginHorizontal:20}}>Quel type d'étude souhaitez vous réaliser ?</Text>
        <View style={{ flexDirection: "row", backgroundColor:'white', marginHorizontal:20}}>
        <View style={{backgroundColor: 'red', width:100, height:100}}></View>
        <View style={{backgroundColor: 'red', width:100, height:100, marginHorizontal:20}}></View>
        <View style={{backgroundColor: 'red', width:100, height:100}}></View>
        </View>
        </Modal.Body>
      </Modal>
        </>
    )
}

export default Modal1

