import React, {useState, useMemo, useEffect} from 'react';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, FormControl, InputGroup  } from 'react-bootstrap';
import Jump from 'react-reveal/Jump';
import Tada from 'react-reveal/Tada';
import {Flip} from 'react-reveal';
import logo from '../images/logo_useradventure.jpg';
import countryList from 'react-select-country-list'
import { BsFillCaretLeftFill, BsFacebook, BsInstagram, BsTwitter , BsYoutube} from "react-icons/bs";
import {LinkContainer} from 'react-router-bootstrap';


const Modal2 = ({show, Quit, phone, ChangePhone, numRue, ChangenumRue, rue, ChangeRue, compAd, ChangeCompAd, codepostale, ChangeCodepostale, ville, ChangeVille, validCommand1, validCommand2, disabledcolor2, value2, changeHandler2, ChangeActive, handleChange, checked}) => {

    const color1 = "#007F8B";
    const color2 = "#988EFA";
    
   


      const options = useMemo(() => countryList().getData(), [])

    return (
        <>
        <Modal show={show} fullscreen={true} onHide={Quit}>
        <Modal.Header closeButton>
          <Modal.Title>
          <Flip top>
          <Image src={logo} style={{height:70, marginLeft:20}} fluid alt="logoUseradventure"/>
          </Flip>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{background: `linear-gradient(to bottom,  ${color1} 0%,${color2} 100%)`}}>
        <Col md={{ span: 10, offset:1  }} lg={{ span: 4, offset: 4 }} style={{marginTop:10,background: 'rgba(0, 0, 0, 0.3)',borderRadius:50}}>
        <a role="button" class="btn" style={{color:'white', fontSize:20, borderWidth:1, borderColor:'white', borderRadius:20, marginLeft:20, marginTop:20, cursor:'pointer' }} onClick={validCommand2}>
        <BsFillCaretLeftFill/>Précédent
        </a>
        <Jump>
          <Col className="text-center" style={{paddingTop:10,color:'white', fontWeight:'bold',fontSize:30}}>
            Inscription
          </Col>
          </Jump>
          <Col className="text-center" style={{color:'white', marginTop:50,fontWeight:'bold',fontSize:30}}>
          Encore quelques petites informations...
          </Col>
          <Col className="text-center" style={{color:'white', marginTop:50,fontSize:20,marginInline:20}}>
          Ces informations nous permettrons d'une part, de favoriser l'organisation de certaines études mais également de vous transmettre vos gains par voie postale (chèque).
          </Col>
          
          
          <div class="form-group" style={{marginInline:20}}>
                             <label class="form-label mt-4"></label>
                            <div class="form-floating mb-3">
                                <input type="text" style={{borderRadius:30}}  value={phone} onChange={ChangePhone} class="form-control" id="floatingInput" placeholder="name@example.com"/>
                                <label for="floatingInput">Téléphone portable *</label>
                            </div>
                            <Col className="text-center"  style={{color:'white', fontWeight:500,fontSize:18, paddingBottom:10}}>Adresse :</Col>
                            <div class="form-floating mb-3">
                                <input type="Prénom" style={{borderRadius:30}} value={numRue} onChange={ChangenumRue}  class="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                                <label for="floatingPrenom">Numéro de rue *</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="Prénom" style={{borderRadius:30}} value={rue} onChange={ChangeRue}  class="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                                <label for="floatingPrenom">Nom de rue *</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="Prénom" style={{borderRadius:30}} value={compAd} onChange={ChangeCompAd}  class="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                                <label for="floatingPrenom">Complément d'adresse</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="Prénom" style={{borderRadius:30}} value={codepostale} onChange={ChangeCodepostale} class="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                                <label for="floatingPrenom">Code postal *</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="Prénom" style={{borderRadius:30}} value={ville} onChange={ChangeVille} class="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                                <label for="floatingPrenom">Ville *</label>
                            </div>
                            <div class="form-floating mb-3" >
                                <select onChange={changeHandler2} value={value2} style={{borderRadius:30}} placeholder="Nationalité *" class="form-control" id="floatinggenre">
                                {
                                options.map( (x) => 
                                  <option key={x.value} value={x.value}>{x.label}</option> )
                                }
                                </select>
                                <label for="floatingPrfloatinggenreenom">Pays *</label>
                            </div>

                            <InputGroup className="mb-3">
                            <InputGroup.Checkbox aria-label="Checkbox for following text input" value={checked}
                                onChange={handleChange}/>
                                <Col className="text-center" style={{color:'white',fontSize:15,marginInline:20}}>
                                                        Les informations que vous avez fournies font l’objet d’un traitement informatisé destiné au recrutement des participants aux études. Le destinataire des données est la société Symplicity UserAdventure.
                            Conformément à la Loi « informatique et liberté » du 6 janvier 1978 modifiée, vous bénéficiez d’un droit d’accès, de rectification et de suppression des données vous concernant. Pour exercer ce droit, veuillez-vous adresser à <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                                            ChangeActive('#/contact')
                                            Quit()} } style={{margin:0, padding:0}}  exact to={{ pathname:"/contact", state:{ version: '1' }}}>
                                            <a>Useradventure</a>
                                            </LinkContainer>.
                                    </Col>
                            </InputGroup>
                            
                            <Tada>
                            <Row style={{justifyContent:'center',alignItems:'center',marginTop:30}}>
        <Col  className="text-center" >
       
                            <a role="button" class="btn"  onClick={()=>validCommand1()} style={{background:'transparent', color:'white'}} >
                    <Row style={{alignItems:'center'}}>
                        <Col xs="4" style={{zIndex:2}}> 
                            <Image src="https://useradventure.net/images/pigeon.svg"  style={{height:115}}/>
                        </Col>
                        <Col xs="8" style={{zIndex:1}}>
                            <Row style={{background: disabledcolor2, borderRadius:30, paddingBlock:20, paddingLeft:40,paddingRight:15, justifyContent:'center',marginLeft:-30, marginTop:-30, fontWeight:'bold', fontSize:18}}>Valider</Row>
                        </Col>
                    </Row>
              </a> 
              </Col>
                    </Row>

              </Tada>          
                  </div>
        </Col>
        </Modal.Body>
      </Modal>
        </>
    )
}


export default Modal2

