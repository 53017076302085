import React, {useState, useMemo, useEffect} from 'react';
import './App.css';
import { useHistory } from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, Button, ButtonGroup, ToggleButton, Form, DropdownButton, Dropdown  } from 'react-bootstrap';
import {Linking, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground,Alert, useWindowDimensions} from 'react-native';
import {Fade, Flip, Zoom, Bounce} from 'react-reveal';
import { FaQuoteRight, FaQuoteLeft } from "react-icons/fa";
import img1 from './images/normal_u31.png';
import img2 from './images/normal_u35.png';
import img3 from './images/normal_u39.png';
import resto from './images/UserGarden.png';
import Secours from './images/petipota.png';
import emmaus from './images/Eurasante.jpeg';
import diabeloop from './images/logo_diabeloop.jpeg';
import Adeo from './images/logo_Adeo.png';
import Teknimed from './images/logoTeknimedHD.png';
import zevent from './images/logo_useconcept_200.png';
import planet from './images/planete.jpg';
import fusee from './images/illu_fusee.png';
import loupe from './images/illu_graph.png';
import qualite from './images/illu_qualite.png';
import "react-datepicker/dist/react-datepicker.css";
import Wave from 'react-wavify'
import Nav from 'react-bootstrap/Nav';


const Home = ({show, setShow, ChangeActive, sendColorbg, sendColor, ActiveRef}) => {
  
const history = useHistory();
const color1 = "#ff8500";
const color2 = "#fe9523";
const color3 = "#fcfcfc";
const color5 = "#0b7482";
const color4 = "#10b2c8";
const [height, setheight]= useState(0);
const [Recrut, setRecrut]= useState(false);
const [Type, setType]= useState(true);
const [show1, setShow1] = useState(false);
const [showTestUser, setshowTestUser] = useState(false);
const [showQuestionnaire, setshowQuestionnaire] = useState(false);
const [showEntretien, setshowEntretien] = useState(false);
const [showAtelier, setshowAtelier] = useState(false);
const [showAutre, setshowAutre] = useState(false);
const [showModal, setshowModal] = useState(false);
const [navcolor, setnavcolor] = useState('light');
const [bg, setbg] = useState("white");
const contact1 = 'maxougames@gmail.com'
const contact2 = 'maxime.aldegheri@useradventure.fr'
const {  width } = useWindowDimensions();

const ChangeBG = (color, variant)=>{
  setbg(color)
  setnavcolor(variant)
}

const ClicBOX = () => {
  window.scrollTo(0, 0)
  history.push("/etude");
}

  return (
    <>
    <div id="top"></div>
    
    <Container style={{background: `linear-gradient(to bottom,  ${color1} 0%,${color2} 50%,${color3} 100%)`}} fluid>
      <Row style={{justifyContent:'center',alignItems:'center', paddingBlock:60}}>
            <Col lg="6" style={{paddingInline:30, paddingBlock:60}}>
                <Row >
                  <Col lg={{span: 10, offset: 1 }}  className="text-lg-start text-center" style={{backgroundColor:''}}>
                    <h1 class="slogan" style={{color:'white', fontWeight:'bold' ,marginBottom:30,lineHeight:1.2,}}>Prenez un bon café, on s'occupe de tout !</h1>
                    <h3  style={{color:'white', fontWeight:'bold' ,marginBottom:0,lineHeight:1.2,}}>Useradventure est la plateforme qui facilite vos études utilisateurs et consommateurs en fonction de vos besoins</h3>
                  </Col>
                </Row>       
            </Col>
            <Col lg="6" style={{justifyContent:'center', paddingBlock:60}}>
             <div className="videop">
                  <Ratio aspectRatio="16x9"> 
                      <iframe title="animation useradventure"  className="rounded" src="https://www.youtube.com/embed/RZYgz4Im77w?rel=0&loop=1" frameborder= "0" allow="autoplay ; fullscreen" allowfullscreen></iframe>
                  </Ratio>
              </div>
            </Col>
      </Row>
    </Container>
    <Container  fluid>
      <Row style={{justifyContent:'center',alignItems:'center'}}>
        <Col  className="text-center" style={{marginTop:-50}}>
        <LinkContainer onClick={()=>{ChangeBG("white",'light')
                sendColorbg("white")
                sendColor("black")}} style={{paddingRight:0, fontWeight:'bold',color: ActiveRef === '/etude' ? '#10b2c8' : 'grey'}}   exact to={{ pathname:"/etude", state:{ version: '1' }}}>
                    <Nav.Link>
              <a aria-expanded={ height !== 0 }
          aria-controls='example-panel'
          role="button" class="btn" id="start"  style={{background:'transparent', color:'white'}} >
                            <Row className="list" style={{background: '#10b2c8', borderRadius:35, paddingBlock:20,paddingInline:40, justifyContent:'center', fontWeight:'bold', fontSize:18}}>Lancer une étude</Row>
              </a> 
              </Nav.Link>
                </LinkContainer>
        </Col>
      </Row>
    </Container>
    <Container style={{marginTop:80, marginBottom:50}}   fluid>
    <Fade bottom>
      <Col className="text-center"  style={{fontSize: 30,color: '#5b5b5b',paddingInline:0, fontWeight:'bold'}}>NOS SOLUTIONS</Col>
      </Fade>
      <Row style={{justifyContent:'center',alignItems:'center', paddingBlock:60}}>
      <Col className="align-self-start" md="3" style={{width:250,marginInline:30}}>
      <Row className="justify-content-center">
    <Col  onClick={ClicBOX} className="box1 rounded"  style={{cursor:'pointer', justifyContent:'center',alignItems:'center',height:250,width:250,marginBlock:50}}>
    <Row className="justify-content-center">
      <Image class="text-center" src={img1}  style={{height:150,width:150, padding:40}}/>
      <p style={{paddingInline:40, color:'#10b2c8', fontWeight:'bold'}} class="text-center">RECRUTEMENT UTILISATEUR</p>
      </Row>
    </Col></Row>
    <p className="text-center"  style={{fontSize: 14, color:'gray'}}>Chez vous? chez nous? Chez eux?
Nous recrutons vos utilisateurs finaux afin
de mener vos études en présentiel ou à
distance</p>
   </Col>
   <Col className="align-self-start"  md="3" style={{width:250,marginInline:30}}>
   <Row className="justify-content-center">
    <Col className="box rounded"   style={{justifyContent:'center',alignContent:'center',height:250,width:250,marginBlock:50}} >
    <View style={{height:60, width:60, marginLeft:205, marginTop:-30,position:'absolute', backgroundColor:'white',borderRadius:30, alignItems:'center', justifyContent:'center', borderColor:"#ff8500",borderWidth:2}}>
      <Text style={{color:"#10b2c8", fontSize:14, fontWeight:'bold'}}>bientôt</Text>
    </View>
    <Row className="justify-content-center">
      <Image src={img2}  style={{height:150,width:150, padding:40}}/>
     <p style={{paddingInline:40, color:'#ed7c00', fontWeight:'bold'}} class="text-center">STUDIES'BOX Outils et facilités</p>
      </Row>
    </Col></Row>
    <p className="text-center"  style={{fontSize: 14, color:'gray'}}>Nous mettons à votre disposition nos outils et
facilités afin de favoriser la réalisation de vos études
</p>
    </Col>
    <Col className="align-self-start" md="3" style={{width:250,marginInline:30}}>
    <Row className="justify-content-center">
    <Col className="box rounded" style={{justifyContent:'center',alignItems:'center',height:250,width:250,marginBlock:50}} >
    <View style={{height:60, width:60, marginLeft:205, marginTop:-30,position:'absolute', backgroundColor:'white',borderRadius:30, alignItems:'center', justifyContent:'center', borderColor:"#ff8500",borderWidth:2}}>
      <Text style={{color:"#10b2c8", fontSize:14, fontWeight:'bold'}}>bientôt</Text>
    </View>
    <Row className="justify-content-center">
      <Image src={img3}  style={{height:150,width:150, padding:40}}/>
      <p style={{paddingInline:20, color:'#ed7c00', fontWeight:'bold'}} class="text-center">POSITIONNEMENT PRODUIT</p>
      </Row>
    </Col></Row>
    <p className="text-center"  style={{fontSize: 14, color:'gray'}}>Utilisez notre système innovant de monitoring
pour suivre l'utilisation réelle de votre produit ou
service par vos utilisateurs</p>
    </Col>
  </Row>
 
 </Container>
   
      <Container style={{marginTop:80}}   fluid>
      <Fade bottom> 
      <Col className="text-center" style={{fontSize: 24,color: '#5b5b5b',paddingInline:30, fontWeight:'bold',marginTop:60}}>ILS NOUS FONT CONFIANCE</Col>
      </Fade>
    </Container>

    <Container style={{marginTop:80,paddingBottom:40}}  >
    <Row className="justify-content-md-center">
    <Col md="3" className="fond_image" style={{display:'flex', justifyContent:'center',alignItems:'center'}} >
      <Image src={emmaus}  style={{height:150, padding:10, justifySelf:'center'}}/>
    </Col>
    <Col md="3" className="fond_image" style={{display:'flex', justifyContent:'center',alignItems:'center'}} >
      <Image src={diabeloop}  style={{height:150, padding:10, justifySelf:'center'}}/>
    </Col>
  </Row>
    <Row className="justify-content-md-center">
    <Col md="3" className="fond_image" style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
      <Image src={zevent}  style={{height:150, padding:40}}/>
    </Col>
    <Col md="3" className="fond_image" style={{display:'flex', justifyContent:'center',alignItems:'center'}} >
      <Image src={resto}  style={{height:150, padding:20}}/>
    </Col>
    <Col md="3" className="fond_image" style={{display:'flex', justifyContent:'center',alignItems:'center'}} >
      <Image src={Secours}  style={{height:150, padding:40}}/>
    </Col>
    </Row>
    <Row className="justify-content-md-center" style={{marginTop:20}}>
    <Col md="3" className="fond_image" style={{display:'flex', justifyContent:'center',alignItems:'center'}} >
      <Image src={Adeo}  style={{height:120, padding:10, justifySelf:'center'}}/>
    </Col>
    <Col md="3" className="fond_image" style={{display:'flex', justifyContent:'center',alignItems:'center'}} >
      <Image src={Teknimed}  style={{height:120, padding:10, justifySelf:'center'}}/>
    </Col>
  </Row>
  
    </Container>


    <Container style={{marginTop:80,paddingBottom:80,paddingInline:40}}  >
    
    <Row className="justify-content-md-center">
    <Col md="1"  style={{display:'flex',alignItems:'center'}}>
      <FaQuoteLeft size={30}  color={"#fe9523"} opacity={0.7}/>
    </Col>
    <Col md="10"  >
    <Row className="justify-content-center" style={{fontSize: 18, color:"#0b7482",fontWeight:'bold'}}>
    Capucine CHAMBARD
    </Row>
    <Row className="justify-content-center" style={{marginTop:5,paddingBottom:30, fontSize: 18, color:"#0b7482",fontWeight:'bold'}}>
    Diabeloop
    </Row>
    <Row className="justify-content-center text-center" >
    UserAdventure a su être très réactif dans le recrutement de patients pour notre étude qualitative. Nous
    avons obtenu des feedbacks de qualité pour intégrer les attentes des clients dans la conception de notre
    produit.
    </Row>
    </Col>
    <Col md="1" style={{display:'flex', justifyContent:'flex-end',alignItems:'flex-end'}} >
      <FaQuoteRight size={30}  color={"#fe9523"} opacity={0.7}/>
    </Col>
    </Row>
</Container>

    <Container style={{background:width <750 ?  `linear-gradient(to bottom,  ${'#ff8500'} 0%,${'#ffffff'} 100%)`:`linear-gradient(to right,  ${'#ff8500'} 0%,${'#ffffff'} 100%)`, paddingBlock:20}} fluid>
    <Row className="justify-content-md-center">
    <Col md="6" className="text-center" style={{display:'flex', justifyContent:'center',alignItems:'center', color:'white', fontWeight:'bold', fontSize:26}}>
    Vous souhaitez en discuter ? 
    </Col>
   
    <Col md="6" className="text-center"  style={{display:'flex', justifyContent:'center',alignItems:'center'}} >
    <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/contact')}} style={{margin:0, padding:0, textDecorationLine:'none'}}  exact to={{ pathname:"/contact", state:{ version: '1' }}}>
        <a role="button" class="btn"  style={{background:'transparent', color:'white', textDecorationLine:'none'}} >
                <Row className="list" style={{background: '#10b2c8', borderRadius:35, paddingBlock:20, paddingInline:40, justifyContent:'center', fontWeight:'bold', fontSize:18,color: 'white', textDecorationLine:'none'}}>Contactez-nous</Row>
        </a> 
        </LinkContainer>
       
    </Col>
  </Row>
    </Container>
    <Container style={{paddingBottom:80}} fluid>
    <Fade bottom> 
      <Col className="text-center" style={{fontSize: 24,color: '#5b5b5b',paddingInline:30, fontWeight:'bold',marginBlock:60}}>NOS PETITS <span style={{color:'#ff8500'}}>+</span> QUI FONT LA DIFFERENCE </Col>
      </Fade>
      <Row style={{justifyContent:'center',alignItems:'center', paddingBlock:30}} className="justify-content-md-center">
    <Col md="5" className="text-center"  style={{display:'flex', justifyContent:'center',alignItems:'center'}} >
    <Image src={planet} fluid style={{maxHeight:450, paddingBlock:40, paddingInline:20}}/>
    </Col>
    <Col md="5" className="text-center text-md-start" >
    <p style={{ justifyContent:'center',alignItems:'center', color:'#10b2c8', fontWeight:'bold', fontSize:26}}>Notre vocation, vous rapprocher de vos utilisateurs et consommateurs</p>
    <p style={{color:'#606060', fontSize:16}}>Particuliers, professionels, patient ou professionnels de santé?</p>
    <p style={{color:'#606060', fontSize:16}}>Notre plateforme permet d'avoir un accès direct à vos consommateurs et utilisateurs finaux.</p>
    <p style={{color:'#606060', fontSize:16}}>Définissez vos critères et profitez de l'expérience UserAdventure pour un recrutement en toute transparence.</p>
    <p style={{color:'#606060', fontSize:16}}>Nos différents contrôles qualités vous garantissent d'accéder à un panel qualifié et pertinent pour vos études. Les panelistes de UserAdventure constituent une communauté bienveillante qui a pour objectif l'amélioration des produits et services.</p>
    </Col>
  </Row>
  <Row style={{justifyContent:'center',alignItems:'center', paddingBlock:30}} className="justify-content-md-center">
    <Col xs={{ order: 2 }}  md={{span: 5, order: 1 }} className="text-center text-md-end" >
    <p style={{ justifyContent:'center',alignItems:'center', color:'#ed7c00', fontWeight:'bold', fontSize:26}}>Des outils et facilités pour faire décoller vos études utilisateurs</p>
    <p style={{color:'#606060', fontSize:16}}>Notre studies'Box vous permettra de faciliter et gagner un temps précieux lors de la réalisation ou du pilotage de vos études</p>
    <p style={{color:'#606060', fontSize:16}}>Tests utilisateurs, entretiens, tests produits, focus group, tri de cartes et toutes les fonctionnalités d'étude dont vous révez directement connectées à vos utilisateurs et consommateurs !</p>
    </Col>
    <Col xs={{ order: 1 }} md={{span: 5, order: 2 }} className="text-center"  style={{display:'flex', justifyContent:'center',alignItems:'center'}} >
    <Image src={fusee} fluid style={{maxHeight:450, paddingBlock:40, paddingInline:20}}/>
    </Col>
  </Row>
  <Row style={{justifyContent:'center',alignItems:'center', paddingBlock:30}} className="justify-content-md-center">
    <Col md="5" className="text-center"  style={{display:'flex', justifyContent:'center',alignItems:'center'}} >
    <Image src={loupe} fluid style={{maxHeight:450, paddingBlock:40, paddingInline:20}}/>
    </Col>
    <Col md="5" className="text-center text-md-start" >
    <p style={{ justifyContent:'center',alignItems:'center', color:'#10b2c8', fontWeight:'bold', fontSize:26}}>Suivre l'utilisation de votre produit ou service une fois dans les mains des consommateurs, c'est bientôt possible</p>
    <p style={{color:'#606060', fontSize:16}}>Notre plateforme de positionnement produit vous permettra de réaliser des benchmarks et de suivre l'utilisation de votre produit ou service par vos consommateurs et de récolter au fil du temps les données qualitatives et quantitatives nécessaires afin de prendre les bonnes decisions.</p>
    <p style={{color:'#606060', fontSize:16}}>Notre module santé <span style={{color:'#ff8500'}}>Lighthouse</span> vous permettra également de réaliser le suivi après commercialisation de votre dispositif médical conformément au réglement <span style={{color:'#ff8500'}}>UE 2017/745</span>.</p>
    </Col>
  </Row>
  <Row style={{justifyContent:'center',alignItems:'center', paddingBlock:30}} className="justify-content-md-center">
    <Col xs={{ order: 2 }}  md={{span: 5, order: 1 }} className="text-center text-md-end" >
    <p style={{ justifyContent:'center',alignItems:'center', color:'#ed7c00', fontWeight:'bold', fontSize:26}}>La qualité avant tout</p>
    <p style={{color:'#606060', fontSize:16}}><span style={{color:'#ff8500'}}>RGPD, loi anti-cadeaux, dispositif transparence</span> et les différents règlements qui entourent vos recrutements et études n'ont pas de secret pour nous.</p>
    </Col>
    <Col xs={{ order: 1 }} md={{span: 5, order: 2 }} className="text-center"  style={{display:'flex', justifyContent:'center',alignItems:'center'}} >
    <Image src={qualite} fluid  style={{maxHeight:450, paddingBlock:40, paddingInline:20}}/>
    </Col>
  </Row>
    </Container>
 <Container style={{backgroundColor:'white',paddingBottom:50}} fluid> 
 <Fade bottom> 
 <Col className="text-center" style={{fontSize: 30,color: '#5b5b5b', fontWeight:'bold'}}>ON SE FAIT UNE PETITE ETUDE <span style={{color:'#ff8500'}}>?</span></Col>
<Col className="text-center" style={{fontSize: 30,color: '#5b5b5b', fontWeight:'bold'}}>JUSTE POUR LA ROUTE</Col>
</Fade>
 </Container>
 <Container style={{ paddingBottom:80}} fluid>
      <Row style={{justifyContent:'center',alignItems:'center'}}>
        <Col  className="text-center" style={{marginTop:0}}>
       
                <LinkContainer onClick={()=>{ChangeBG("white",'light')
                sendColorbg("white")
                sendColor("black")}} style={{paddingRight:0, fontWeight:'bold',color: ActiveRef === '/etude' ? '#10b2c8' : 'grey'}}   exact to={{ pathname:"/etude", state:{ version: '1' }}}>
                    <Nav.Link><a role="button" class="btn" style={{background:'transparent', color:'white'}} >
                            <Row className="list" style={{background: '#10b2c8', borderRadius:35, paddingBlock:20, paddingInline:40, justifyContent:'center', fontWeight:'bold', fontSize:18,color: 'white'}}>Lancer une étude</Row>
              </a> 
              </Nav.Link>
                </LinkContainer>
        </Col>
      </Row>
    </Container>
    <Wave fill='#10b2c8' style={{padding:0}} 
        paused={false}
        options={{
          height: 40,
          amplitude: 70,
          speed: 0.2,
          points: 2
        }}
  />
    <Container style={{background: `linear-gradient(to bottom,  ${'#10b2c8'} 0%,${'#007c8c'} 100%)`, paddingBottom:80}} fluid>
    <Col lg={{ offset: 0 }} className="text-center"  style={{fontSize: 30,color: 'white',paddingInline:0, fontWeight:'bold'}}>QUELQUES QUESTIONS <span style={{color:'#ff8500'}}>?</span></Col>
    <Row className="justify-content-md-center" style={{marginTop:80}}>
    <Col md="10">
    <Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header ><span style={{fontWeight:500, fontSize:18}}>Pourquoi UserAdventure ?</span></Accordion.Header>
    <Accordion.Body>
    <p>La mission de UserAdventure est de faciliter la recherche utilisateurs et consommateurs dans le monde.</p>
    Nous avons également à cœur de fournir un service responsable permettant d’une part  aux participants d’être dans des conditions optimales lors des différentes passations et d’autre part de soutenir des associations et projets d’innovation.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>Qui peut effectuer un recrutement ?</span></Accordion.Header>
    <Accordion.Body>
    <p>Toute personne qui souhaite réaliser une étude auprès d’utilisateurs ou de consommateurs:</p>
    Petite, moyenne, grande entreprise, étudiants, particuliers, universitaire etc.
</Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="2">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>Comment les participants aux études sont-ils recrutés ?</span></Accordion.Header>
    <Accordion.Body>
    <p>Ils sont recrutés en grande partie directement dans la communauté UserAdventure qui est engagée dans l’amélioration des produits, services et innovations.  Cela permet également d’avoir accès à des critères plus fins de recrutement et un contrôle qualité des personnes répondant à vos études (caractéristiques, anciennes études réalisées, comportement en test etc.).</p>
    Le cas échéant, UserAdventure peut être amené à utiliser des canaux d’acquisition spécifiques (web, réseaux sociaux, etc.) afin de rechercher les bons profils qu'il vous faut. :) 
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="3">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>Que puis-je faire comme type de recrutement ?</span></Accordion.Header>
    <Accordion.Body>
    <p>Nous sommes en capacité de recruter de nombreux types de profils dans le respect des réglementations en vigueur : particulier, professionnel, patient, professionnel de santé etc.</p>
    Vous pouvez recruter des participants afin de mener des études en présentiel comme à distance et en groupe comme en individuel.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="4">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>Combien de temps faut-il pour recruter des participants ?</span></Accordion.Header>
    <Accordion.Body>
    <p>Dès que nous avons validé avec vous les profils et le questionnaire de recrutement, notre plateforme commence à contacter les meilleurs profils pour votre étude. Vous obtenez vos premiers participants en quelques heures seulement !</p>
    Il parait même que nous finalisons des recrutements entiers en seulement 24h!
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="5">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>Comment le prix est-il calculé ?</span></Accordion.Header>
    <Accordion.Body>
    <p>Pas de mauvaise surprise, vous payez uniquement au participant recruté en fonction des options choisies. Pas de frais de dossier ou de gestion qui s’ajoutent à votre facture en fin d’intervention.</p>
    Le prix unitaire est calculé en fonction de la difficulté de recrutement du type de profil et du montant de la contrepartie donnée au participant.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="6">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>Comment commencer mon recrutement ?</span></Accordion.Header>
    <Accordion.Body>
    <p>Il suffit de pré-paramétrer votre recrutement directement <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/contact')}} style={{margin:0, padding:0}}  exact to={{ pathname:"/etude"}}>
                  <a>ici</a>
                </LinkContainer> ou nous contacter directement <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/contact')}} style={{margin:0, padding:0}}  exact to={{ pathname:"/contact"}}>
                  <a>ici</a>
                </LinkContainer>.</p> 
    Nous répondrons dans les plus brefs délais.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="7">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>La “studie’s box” c’est quoi ?</span></Accordion.Header>
    <Accordion.Body>
    La “studie’s box” regroupe les fonctionnalités logiciels que nous développons pour et avec vous afin de faciliter la réalisation de vos différentes études : Entretiens, tests utilisateurs, tri de carte etc.
    </Accordion.Body>
  </Accordion.Item>
</Accordion>
</Col>
</Row>
<Col className="text-center" style={{fontSize: 20, paddingInline:30,paddingBlock:20, fontWeight:'bold', display:'none'}}><LinkContainer onClick={()=>{ window.scrollTo(0, 0)}} style={{margin:0, padding:0, color:'white', textDecorationLine:'underline'}}  exact to={{ pathname:"/faq", state:{ version: '1' }}}>
                  <Nav.Link>Tout la F.A.Q.</Nav.Link>
                </LinkContainer></Col>
    </Container>
    
    </>

  );
}



export default Home