import React, {useState, useMemo} from 'react';
import { useHistory } from "react-router-dom";
import {Fade, Flip} from 'react-reveal';
import Tada from 'react-reveal/Tada';
import Jump from 'react-reveal/Jump';
import img2 from './images/carteFrance.png';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, InputGroup, FormControl, Button } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import ModalFin from './components/ModalFin';
import {Linking, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground,Alert, useWindowDimensions} from 'react-native';
import mailchimpFactory from '@mailchimp/mailchimp_transactional/src/index.js';
const mailchimp = mailchimpFactory("3MaihbLvVMRazJCeofwVyw");


const Contact = () => {

  const contact1 = 'julien.aldegheri@useconcept.com'
  const contact2 = 'maxime.aldegheri@useradventure.fr'
 
    const history = useHistory();
    const color1 = "#ff7500";
    const color2 = "#fe9523";
    const color3 = "#fcfcfc";
    const color5 = "#0b7482";
    const color4 = "#10b2c8";
    const [showModal, setshowModal] = useState(false);
    const URL = 'https://www.useradventure.net/'
    const [Nom, setNom] = useState('');
    const [Prenom, setPrenom] = useState('');
    const [Mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [sujet, setSujet] = useState('');
    const [msg, setMsg] = useState('');
    const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
    const [disabled, setdisabled] = useState(false);
    const {  width } = useWindowDimensions();

    

      const ChangeMail = (event) => {
        setMail(event)
        if(event !== '' && msg !==''){
          setdisabledcolor(`linear-gradient(to right,  ${color1} 20%,${color2} 100%)`)
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };

  

      // const ChangeSujet = (event) => {
      //   setSujet(event)
      //   if(event !== '' && Mail !=='' && msg !==''){
      //     setdisabledcolor(`linear-gradient(to right,  ${color1} 20%,${color2} 100%)`)
      //     setdisabled(true)
      //   } else {
      //     setdisabledcolor('#DCDCDC')
      //     setdisabled(false)
      //   }
      //  console.log(event)
      // };

      const ChangeMsg = (event) => {
        setMsg(event)
        if(event !== '' && Mail !==''){
          setdisabledcolor(`linear-gradient(to right,  ${color1} 20%,${color2} 100%)`)
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };


      const run = async (message) => {
        const response = await mailchimp.messages.sendTemplate({
          template_name: "UAproContact",
          "template_content": [{}],
          message: message,
        });
        console.log(response);
        setMsg('')
        setMail('')
        setSujet('')
        setshowModal(true)
        //alert('Merci pour ton message !')
       // history.push("/")
      };

      const validCommand1 = () => {
        var position = Mail.indexOf("@");
        var reste = Mail.substr(-position);
        var posit = reste.indexOf(".");
        //alert(posit + position + Mail )
        if(position !== -1 && posit !== -1 ){
        if(disabled){
          const message = {
            from_name: "UserAdventurePro",
            from_email: "contact@useradventure.fr",
            subject: `Contact UserAdventurePro`,
            to: [
              {
                email: contact1,
                type: "to"
              },
              {
                email: contact2,
                type: "cc"
              }
            ],
            "merge_vars": [
              {
                  "rcpt": contact1,
                  "vars": [
                    {
                      "name": "EMAILPRO",
                      "content": Mail
                    },
                    {
                      "name": "TYPE",
                      "content": "Contact"
                    },
                    {
                      "name": "TEST",
                      "content": sujet
                    },
                    {
                      "name": "MOD",
                      "content": msg
                    },
                  ]
              },
              {
                "rcpt": contact2,
                "vars": [
                  {
                    "name": "EMAILPRO",
                    "content": Mail
                  },
                  {
                    "name": "TYPE",
                    "content": "Contact"
                  },
                  {
                    "name": "TEST",
                    "content": sujet
                  },
                  {
                    "name": "MOD",
                    "content": msg
                  },
                ]
            }
          ]
          }
        run(message) 
          }else{
            alert('Veuillez remplir tous les champs, Merci!')
          }
          }else{
        alert('Email non valide')
      }
    }

    return (
        <div>
        <View style={{flexDirection: "row", alignItems: 'center', justifyContent:'space-between', background: `linear-gradient(to right,  ${color5} 0%,${color4} 100%)`, paddingHorizontal:40,paddingVertical:15}} >
  <Text  style={{color:'white', fontWeight:'bold', fontSize: 20, cursor: 'pointer' }}>CONTACT</Text>
   
    </View>
        <Container fluid style={{paddingTop : 30, paddingBottom : 10}}>
        <Col lg={{ span: 10, offset:1  }} className="rounded" style={{padding:30, borderColor:'orange', borderWidth:2, borderStyle:'solid'}} >
         <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}><p style={{ color:'orange', fontWeight:'bold', fontSize: 24}} class="text-center">Votre message d'amour pour nous</p>
         </Row>
         <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}> <p style={{ color:'orange', fontWeight:'bold', fontSize: 14}} class="text-center">{disabled ? '':'Toutes les informations sont obligatoires'}</p>
      </Row>
        <Col md={{ span: 10, offset:1  }} lg={{ span: 6, offset: 3 }} style={{marginTop:0,borderRadius:50}}>
       
          <div class="form-group" style={{marginInline:20}}>
          <label class="form-label mt-3"></label>
                            {/* <div style={{ marginBottom:20}}>
                                <label style={{fontSize: 20,color: '#007F8B', marginBottom:10, fontWeight:'bold'}}>Sujet</label>
                                <input type="Nom" value={sujet} onChange={e =>ChangeSujet(e.target.value)} style={{borderRadius:5, borderColor:'#10b2c8', borderWidth:1, borderStyle:'solid'}} class="form-control"/>
                            </div> */}
                            <div style={{ marginBottom:20}}>
                                <label style={{fontSize: 20,color: '#007F8B', marginBlock:10, fontWeight:'bold'}}>Message</label>
                                <textarea  rows="5" type="Prénom" value={msg} onChange={e =>ChangeMsg(e.target.value)} style={{borderRadius:5, borderColor:'#10b2c8', borderWidth:1, borderStyle:'solid', height:150, marginBottom:0}} class="form-control"/>
                            </div>  
                            <div>
                                <label style={{fontSize: 20,color: '#007F8B', marginBlock:10, fontWeight:'bold'}}>Email</label>
                                <input type="mail" value={Mail} onChange={e =>ChangeMail(e.target.value)} style={{borderRadius:5, borderColor:'#10b2c8', borderWidth:1, borderStyle:'solid'}} class="form-control"/>
                            </div>
                            <Row style={{justifyContent:'center',alignItems:'center',marginTop:30}}>
        <Col  className="text-center" >
       
                            <a role="button" class="btn"  onClick={()=>validCommand1()} style={{background:'transparent', color:'white'}} >
                            <Row style={{background: disabledcolor, borderRadius:35, paddingBlock:20,paddingInline:50, justifyContent:'center', fontWeight:'bold', fontSize:18}}>Envoyer</Row>
                            </a> 
              </Col>
                    </Row>

                     
                  </div>
        </Col>
        </Col>
        </Container>
        <Container fluid style={{paddingTop : 30, paddingBottom : 10}}>
        <Col style={{paddingTop : 30, paddingBottom : 10}}>
        <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}><p style={{ color:'orange', fontWeight:'bold', fontSize: 24}} class="text-center">Nous situer</p></Row>
        <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}> <p style={{ color:'grey', fontSize: 14}} class="text-center">260 rue Salvador allende Batiment B 59120 Loos</p></Row>
        <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}> <p href="tel:+33652277731" style={{ color:'grey', fontSize: 14}} class="text-center">Nous sommes joignable au <a href="tel:+33652277731">06 52 27 77 31</a> pour toute demande particuliere</p></Row>
        <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}><p style={{ color:'grey', fontSize: 14}} class="text-center">Email : <a href="mailto:contact@useradventure.fr" style={{  fontSize: 14}} class="text-center"> contact@useradventure.fr</a></p></Row>
        <div class="container2" style={{zIndex:1, paddingTop:width <600 ? '50%':'17%', marginTop:30}}>
            <iframe style={{zIndex:1}} class="responsive-iframe" src="https://maps.google.com/maps?q=260%20rue%20Salvador%20Allende%20%2059120%20LOOS&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
        </div>
        <Row class="container3" style={{justifyContent:'center',alignItems:'center', padding: 0, marginTop: -130, zIndex:2}}> 
        <Image src={img2} fluid  style={{width:300, padding:40, zIndex:2}}/>
        </Row>
       </Col></Container>
        <ModalFin show={showModal} Quit={() => {setshowModal(false); history.push("/")}} />
        
       <Col style={{height:1, backgroundColor:'black'}}></Col>
        </div>
    )
}

export default Contact
