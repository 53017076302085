import React from 'react';
import {BrowserRouter as Router } from "react-router-dom";
import Site from './Site';
//import 'bootswatch/dist/morph/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {


  return (
    <>
      <Router>
          <Site/>
      </Router>
    </>

    
  );
}

export default App;
