import React, {useState, useMemo, useEffect} from 'react';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup  } from 'react-bootstrap';
import Jump from 'react-reveal/Jump';
import Tada from 'react-reveal/Tada';
import {Flip} from 'react-reveal';
import logo from '../images/illu_pigeon.png';
import countryList from 'react-select-country-list'
import { BsFillCaretLeftFill, BsFacebook, BsInstagram, BsTwitter , BsYoutube} from "react-icons/bs";
import {BrowserView, MobileView, isMobile, isIOS, isAndroid} from 'react-device-detect';

const Modal3 = ({show, Quit, validCommand3}) => {

    const color = '#febf01';
    const color1 = "#007F8B";
    const color2 = "#988EFA";


    return (
        <>
        <Modal show={show} onHide={Quit} aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header style={{justifyContent:'center',alignItems:'center', borderWidth:0}}>
        <Tada>
         <Image src={logo} style={{height:160, marginLeft:0, marginTop:-60}} fluid alt="logoUseradventure"/>
         </Tada>
        </Modal.Header>
        <Modal.Body style={{background: 'white'}}>
        <Col className="text-center" style={{fontSize: 20,color: '#007F8B',paddingInline:30, fontWeight:'bold'}}>Bravo ! tu fais désormais parti de l'aventure</Col>
        <Col className="text-center" style={{fontSize: 20,color: '#007F8B',paddingInline:30, fontWeight:'bold', marginTop:20}}>Un mail de confirmation a été envoyé sur ta boîte mail et tu recevras bientôt des études à réaliser !</Col>      
        <Col  style={{display:'flex', justifyContent:'center'}} >
            <ListGroup style={{ paddingTop:30}}>
              <ListGroup.Item className="text-center"  style={{fontWeight:'bold',fontSize: 20,borderWidth:0, backgroundColor:'transparent', color:"#007F8B"}}>Tu peux également nous suivre
sur nos différents réseaux pour ne rien louper.</ListGroup.Item>
            {
              !isMobile &&
           
            <BrowserView>
            <ListGroup.Item style={{fontSize:30,display:'flex',justifyContent:'space-around',borderWidth:0, backgroundColor:'transparent', color:color}}>
              <BsFacebook onClick={() =>{window.open("https://www.facebook.com/UserAdventure-100727149117995/", '_blank')}} style={{cursor:'pointer'}}/>
              <BsInstagram onClick={() =>{window.open("https://www.instagram.com/useradventurefrance/", '_blank')}} style={{cursor:'pointer'}} />
              <BsTwitter onClick={() =>{window.open("https://twitter.com/UserAdventureFR", '_blank')}} style={{cursor:'pointer'}} />
              <BsYoutube onClick={() =>{window.open("https://www.youtube.com/channel/UCfD1G2iLQvYxjDX-XVSewvw", '_blank')}} style={{cursor:'pointer'}}/>
            </ListGroup.Item>
            </BrowserView>  }
            <MobileView>
            {isIOS &&
            <ListGroup.Item style={{fontSize:30,display:'flex',justifyContent:'space-around',borderWidth:0, backgroundColor:'transparent', color:color}}>
              <BsFacebook onClick={() =>{window.location.href="fb://profile/100727149117995"}} style={{cursor:'pointer'}}/>
              <BsInstagram onClick={() =>{window.open("https://www.instagram.com/useradventurefrance/", '_blank')}} style={{cursor:'pointer'}} />
              <BsTwitter onClick={() =>{window.open("https://twitter.com/UserAdventureFR", '_blank')}} style={{cursor:'pointer'}} />
              <BsYoutube  onClick={() =>{window.location.href="youtube://channel/UCfD1G2iLQvYxjDX-XVSewvw"}} style={{cursor:'pointer'}}/>
              </ListGroup.Item>}
              {isAndroid &&
            <ListGroup.Item style={{fontSize:30,display:'flex',justifyContent:'space-around',borderWidth:0, backgroundColor:'transparent', color:color}}>
              <BsFacebook onClick={() =>{window.location.href="fb://page/100727149117995"}} style={{cursor:'pointer'}}/>
              <BsInstagram onClick={() =>{window.open("https://www.instagram.com/useradventurefrance/", '_blank')}} style={{cursor:'pointer'}} />
              <BsTwitter onClick={() =>{window.open("https://twitter.com/UserAdventureFR", '_blank')}} style={{cursor:'pointer'}} />
              <BsYoutube onClick={() =>{window.open("https://www.youtube.com/channel/UCfD1G2iLQvYxjDX-XVSewvw", '_blank')}} style={{cursor:'pointer'}}/>
              </ListGroup.Item>}
            </MobileView>
            </ListGroup>
            </Col>               
        
        </Modal.Body>
        <Modal.Footer style={{justifyContent:'center',alignItems:'center', borderWidth:0}}>
        
                            <a role="button" class="btn"  onClick={()=>validCommand3()} style={{background:'transparent', color:'white'}} >
                    
                        <Col  style={{zIndex:1}}>
                            <Row style={{background: `linear-gradient(to bottom,  ${color1} 0%,${color2} 100%)`, borderRadius:30, paddingBlock:10,paddingInline:50, justifyContent:'center', marginTop:10, fontWeight:'bold', fontSize:18, color:'white'}}>OK</Row>
                        </Col>
                   
              </a> 
      </Modal.Footer>
      </Modal>
        </>
    )
}

export default Modal3

