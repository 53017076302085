import React, {useState, useMemo, useEffect} from 'react';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, Button  } from 'react-bootstrap';
import {Linking, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground,Alert} from 'react-native';
import Jump from 'react-reveal/Jump';
import Tada from 'react-reveal/Tada';
import {Flip} from 'react-reveal';
import logo from '../images/logo_useradventure.jpg';
import countryList from 'react-select-country-list'


const ModalSplus = ({show, Quit}) => {

  const color1 = "#ff8500";
  const color2 = "#fe9523";
  const color3 = "#fcfcfc";
  const color5 = "#0b7482";
  const color4 = "#10b2c8";

    return (
       
        <Modal  show={show}  onHide={Quit} centered>
         
         <Modal.Header className="modal-header border-0" style={{justifyContent:'center',alignContent:'center',background: `linear-gradient(to bottom,  ${color1} 0%,${color2} 65%,${color3} 100%)`}} closeButton closeVariant="white">
          <Modal.Title id="example-modal-sizes-title-sm">
          <Col style={{color:'white',fontWeight:'bold', fontSize:22, marginInline:20}}>En savoir plus</Col>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{background: `white`}}>
      
          <Row>
        <Col style={{fontWeight:'bold', fontSize:22, marginInline:20}}>Le test est modéré :</Col>
        </Row>

        <Row>
        <Col style={{ fontSize:18, marginInline:20,marginTop: 10}}>Le test est animé par un expérimentateur ou un responsable d’étude. Le sujet n’est pas autonome lors de la réalisation du test.</Col>
        </Row>
        
         </Modal.Body>
         <Modal.Footer class="modal-footer border-0" style={{justifyContent: 'center', alignItems: 'center'}}>
         <TouchableOpacity onPress={Quit} style={{background: color4,borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }, height:40, width:160}}>
         <Text style={{color:'white',fontWeight:'bold', fontSize:18, marginHorizontal:5}}>J'ai compris !</Text>
         </TouchableOpacity>
        </Modal.Footer>
        
       
      </Modal>
       
    )
}

export default ModalSplus

