import React from 'react'
import { Container, Row, Col, Image, ListGroup } from 'react-bootstrap';
import {BsFacebook, BsInstagram, BsTwitter , BsYoutube, BsLinkedin} from "react-icons/bs";
import { FaChevronUp } from "react-icons/fa";
import {BrowserView, MobileView, isMobile, isIOS, isAndroid} from 'react-device-detect';
import {LinkContainer} from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';
import img5 from '../images/mrstudies.svg';


const Footer = ({colorbg, color, ChangeActive, sendColorbg, sendColor, setShow3, setOeuf, Active}) => {

console.log('ActiveRef',Active)
  const color1 = "#fe9523";
  const color2 = "#ff8500";
 
        return ( <Container style={{background: colorbg,paddingTop:30}}  fluid>
            <Row className="justify-content-md-center" style={{margin:0 }}>
            <Col md="3" className="text-start">
              <ListGroup style={{borderWidth:0, paddingBottom:60}}>
              <ListGroup.Item style={{fontWeight:'bold',borderWidth:0,fontSize: 20, backgroundColor:'transparent', color:color}}>À propos</ListGroup.Item>
              {/* <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:color}}>
                <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                ChangeActive('/blog')
                sendColorbg("white")
                sendColor("black")}} style={{margin:0, padding:0, color:color}}  exact to={{ pathname:"/blog", state:{ version: '1' }}}>
                  <Nav.Link>Blog</Nav.Link>
                </LinkContainer></ListGroup.Item> */}
              <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:color, display:'none'}}>Blog</ListGroup.Item>
              <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:color}}>
                <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/contact')
                 sendColorbg("white")
                sendColor("black")}} style={{margin:0, padding:0, color:color}}  exact to={{ pathname:"/contact", state:{ version: '1' }}}>
                  <Nav.Link>Nous contacter</Nav.Link>
                </LinkContainer></ListGroup.Item>
            </ListGroup>
            </Col>
            <Col md="3"  className="text-start" >
            <ListGroup style={{ paddingBottom:60}}>
            <ListGroup.Item style={{fontWeight:'bold',fontSize: 20,borderWidth:0, backgroundColor:'transparent', color:color}}>Informations</ListGroup.Item>
              <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:color, display:'none'}}>
                <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/faq')
                 sendColorbg("white")
                sendColor("black")}} style={{margin:0, padding:0, color:color}}  exact to={{ pathname:"/faq", state:{ version: '1' }}}>
                  <Nav.Link>F.A.Q.</Nav.Link>
                </LinkContainer></ListGroup.Item>
              <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:color}}> <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/mention')  
                 sendColorbg(`linear-gradient(to bottom,  ${color1} 0%,${color2} 70%)`)
                  sendColor("white")
                 }} style={{margin:0, padding:0, color:color}}  exact to={{ pathname:"/mention", state:{ version: '1' }}}>
                  <Nav.Link>Mentions légales / CGU</Nav.Link>
                </LinkContainer></ListGroup.Item>
                <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:color}}> <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/PPDP')  
                 sendColorbg(`linear-gradient(to bottom,  ${color1} 0%,${color2} 70%)`)
                  sendColor("white")
                 }} style={{margin:0, padding:0, color:color}}  exact to={{ pathname:"/PPDP", state:{ version: '1' }}}>
                  <Nav.Link>Politique de protection<br/> des données personnelles</Nav.Link>
                </LinkContainer></ListGroup.Item>
            </ListGroup>
            </Col>
            <Col md="3"  className="text-start" >
            <ListGroup style={{ paddingBottom:60}}>
              <ListGroup.Item style={{fontWeight:'bold',fontSize: 20,borderWidth:0, backgroundColor:'transparent', color:color}}>Suivez-nous sur les réseaux</ListGroup.Item>
            {
              !isMobile &&
           
            <BrowserView>
            <ListGroup.Item style={{fontSize:30,display:'flex',borderWidth:0, backgroundColor:'transparent', color:color}}>
              <BsLinkedin onClick={() =>{window.open("https://www.linkedin.com/company/useradventure/", '_blank')}} style={{cursor:'pointer',marginRight:25}} />
              <BsTwitter onClick={() =>{window.open("https://twitter.com/UserAdventureFR", '_blank')}} style={{cursor:'pointer',marginRight:25}} />
              <BsYoutube onClick={() =>{window.open("https://www.youtube.com/channel/UCfD1G2iLQvYxjDX-XVSewvw", '_blank')}} style={{cursor:'pointer',marginRight:25}}/>
            </ListGroup.Item>
            </BrowserView>  }
            <MobileView>
            {isIOS &&
            <ListGroup.Item style={{fontSize:30,display:'flex',justifyContent:'space-around',borderWidth:0, backgroundColor:'transparent', color:color}}>
              <BsLinkedin onClick={() =>{window.open("https://www.linkedin.com/company/useradventure/", '_blank')}} style={{cursor:'pointer',marginRight:25}} />
              <BsTwitter onClick={() =>{window.open("https://twitter.com/UserAdventureFR", '_blank')}} style={{cursor:'pointer',marginRight:25}} />
              <BsYoutube  onClick={() =>{window.location.href="youtube://channel/UCfD1G2iLQvYxjDX-XVSewvw"}} style={{cursor:'pointer',marginRight:25}}/>
              </ListGroup.Item>}
              {isAndroid &&
            <ListGroup.Item style={{fontSize:30,display:'flex',justifyContent:'space-around',borderWidth:0, backgroundColor:'transparent', color:color}}>
              <BsLinkedin onClick={() =>{window.open("https://www.linkedin.com/company/useradventure/", '_blank')}} style={{cursor:'pointer',marginRight:25}} />
              <BsTwitter onClick={() =>{window.open("https://twitter.com/UserAdventureFR", '_blank')}} style={{cursor:'pointer',marginRight:25}} />
              <BsYoutube onClick={() =>{window.open("https://www.youtube.com/channel/UCfD1G2iLQvYxjDX-XVSewvw", '_blank')}} style={{cursor:'pointer',marginRight:25}}/>
              </ListGroup.Item>}
            </MobileView>
            </ListGroup>
            </Col>
          </Row>
        <a onClick={()=>{ window.scrollTo(0, 0)}} class="flash" style={{cursor:'pointer'}}>
            <Row style={{justifyContent:'center',alignItems:'center', cursor:'pointer'}}>
              <FaChevronUp style={{fontSize:30, color:"#007F8B"}}/></Row>
        
              <Image src={img5}   style={{height:90}}/>
            </a>
           
            </Container>
        )
    }


export default Footer
