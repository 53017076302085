import React, {useState, useMemo, useEffect} from 'react';
import './App.css';
import {LinkContainer} from 'react-router-bootstrap';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, Button, ButtonGroup, ToggleButton, Form, DropdownButton, Dropdown  } from 'react-bootstrap';
import {Linking, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground,Alert} from 'react-native';
import {Fade, Flip, Zoom, Bounce} from 'react-reveal';
import { TiChevronLeft, TiChevronRight } from "react-icons/ti";
import * as Icon from "react-icons/im";
import Checkbox from "react-custom-checkbox";
import ModalSplus from './components/ModalSplus'
import ModalFin from './components/ModalFin'
import {useHistory } from "react-router-dom";
import ModalNumber from './components/ModalNumber'
import mailchimpFactory from '@mailchimp/mailchimp_transactional/src/index.js';
const mailchimp = mailchimpFactory("3MaihbLvVMRazJCeofwVyw");

const Questionnaire = ({show, toggle, contact1, contact2}) => {
    const history = useHistory();
    const [Question1, setQuestion1]= useState(false);
    const [Question2, setQuestion2]= useState(false);
    const [show1, setShow1] = useState(false);
    const [showModal, setshowModal] = useState(false);
    const [showModalNumber, setshowModalNumber] = useState(false);
    const [ShowParticipant, setShowParticipant]= useState(false);
    const [EmailTest, setEmailTest]= useState('');
    const [checked1, setChecked1] = useState('');
    const [bg1, setbg1] = useState('transparent');
    const [bg2, setbg2] = useState('transparent');
    const [col1, setcol1] = useState('#007984');
    const [col2, setcol2] = useState('#007984');
    const [checked2, setChecked2] = useState('');
    const [checked3, setChecked3] = useState('');
    const [bg3, setbg3] = useState('transparent');
    const [bg4, setbg4] = useState('transparent');
    const [col3, setcol3] = useState('#007984');
    const [col4, setcol4] = useState('#007984');
    const [checked4, setChecked4] = useState('');
    const [bordercolor1, setbordercolor1] = useState('rgba(254, 132, 2, 1)');
    const [bordercolor2, setbordercolor2] = useState('rgba(254, 132, 2, 1)');
    const [bordercolor3, setbordercolor3] = useState('rgba(254, 132, 2, 1)');
    const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
    const [disabled, setdisabled] = useState(false);
    const [disabledcolor2, setdisabledcolor2] = useState('#DCDCDC');
    const [disabled2, setdisabled2] = useState(false);
    const [disabledcolor3, setdisabledcolor3] = useState('#DCDCDC');
    const [disabled3, setdisabled3] = useState(false);
    const [Montant, setMontant] = useState(0);
    const [Mont, setMont] = useState(0);
    const [checkParticipant1, setcheckParticipant1] = useState(false);
    const [checkParticipant2, setcheckParticipant2] = useState(false);
    const [checkParticipant3, setcheckParticipant3] = useState(false);
    const [checkParticipant4, setcheckParticipant4] = useState(false);

    useEffect(() => {
      setQuestion1(false)
      setQuestion2(false)
  }, []);
  
    const changeMoins = () => {
      if(Montant > 0){
        setMontant(Number(Montant) - 1)
      }
    }
  
    const changePlus = () => {
        setMontant(Number(Montant) + 1)
    }
  
    const Suivant1 = () => {
    setQuestion1(true);
    setbordercolor1('rgba(16, 178, 200, 1)')
  }
  
  const Verif1 = (checked1,checked2) => {
    setdisabledcolor('#DCDCDC')
    setdisabled(false)
    setbordercolor1('rgba(254, 132, 2, 1)')
    Verif3(EmailTest, false, disabled2)
    console.log('checked',checked1,checked2,checked3,checked4)
    if((checked1  !== "" && checked2 !== "")){
      setdisabledcolor('rgba(254, 132, 2, 1)')
      setdisabled(true)
      setbordercolor1('rgba(16, 178, 200, 1)')
      Verif3(EmailTest, true, disabled2)
    }
     
  }
  
  const Verif2 = (MontC,p1,p2,p3,p4) => {
    setMont(MontC)
    setdisabledcolor2('#DCDCDC')
    setdisabled2(false)
    setbordercolor2('rgba(254, 132, 2, 1)')
    Verif3(EmailTest, disabled, false)
    console.log('checked',MontC,p1,p2,p3,p4)
    if(MontC !== 0 && ( p1 ||  p2 || p3 || p4 )) {
      setdisabledcolor2('rgba(254, 132, 2, 1)')
      setdisabled2(true)
      setbordercolor2('rgba(16, 178, 200, 1)')
      Verif3(EmailTest, disabled, true)}
    }
    
  
  
    const Verif3 = (mailC, D, D2) => {
      console.log('mailC',mailC)
      console.log('disabled2',D2)
      console.log('disabled',D)
      setdisabledcolor3('#DCDCDC')
      setdisabled3(false)
      setbordercolor3('rgba(254, 132, 2, 1)')
      if(mailC !== ""){
        setbordercolor3('rgba(16, 178, 200, 1)')
      if(D2 &&  D){
      
      setdisabledcolor3('rgba(254, 132, 2, 1)')
        setdisabled3(true)
      }}}
  
    const run = async (message) => {
       setshowModal(true)
      const response = await mailchimp.messages.sendTemplate({
        template_name: "UAproQuestionnaire",
        "template_content": [{}],
        message: message,
      });
      console.log(response);
    };
  
    const Valid = () => {
  
      const p1 = checkParticipant1 ? 'Particuliers ,':''
      const p2 = checkParticipant2 ? 'Professionnels ,':''
      const p3 = checkParticipant3 ? 'Patients ,':''
      const p4 = checkParticipant4 ? 'Professionnels de santé':''
      
      
      const message = {
        from_name: "UserAdventurePro",
        from_email: "contact@useradventure.fr",
        subject: `Commande UserAdventurePro`,
        to: [
          {
            email: contact1,
            type: "to"
          },
          {
            email: contact2,
            type: "cc"
          }
        ],
        "merge_vars": [
          {
              "rcpt": contact1,
              "vars": [
                {
                  "name": "EMAILPRO",
                  "content": EmailTest
                },
                {
                  "name": "TYPE",
                  "content": "Questionnaire"
                },
                {
                  "name": "TEST",
                  "content": checked1
                },
                {
                  "name": "MOD",
                  "content": checked2
                },
                {
                  "name": "NBP",
                  "content": `${Montant}`
                },
                {
                  "name": "P",
                  "content": `(${p1}${p2}${p3}${p4})`
                },
              ]
          },
          {
            "rcpt": contact2,
            "vars": [
              {
                "name": "EMAILPRO",
                "content": EmailTest
              },
              {
                "name": "TYPE",
                "content": "Questionnaire"
              },
              {
                "name": "TEST",
                "content": checked1
              },
              {
                "name": "MOD",
                "content": checked2
              },
              {
                "name": "NBP",
                "content": `${Montant}`
              },
              {
                "name": "P",
                "content": `(${p1}${p2}${p3}${p4})`
              },
            ]
        }
      ]
      }
    run(message)
    }
  
      
    return (
  show && <Zoom>
    <Col md={{ span: 10, offset: 1 }} style={{ padding:40}}>
    <Row className="justify-content-center" style={{justifyContent:'center',alignItems:'center'}}>
    <p style={{paddingInline:40, color:'#10b2c8', fontWeight:'bold', fontSize: 24}} class="text-center">QUESTIONNAIRE</p>
    <Row className="rounded" style={{padding:30, borderColor:bordercolor1, borderWidth:2, borderStyle:'solid'}} >
    <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}>
        <Col><p style={{ color:bordercolor1, fontWeight:'bold', fontSize: 24}} class="text-center">Votre questionnaire</p></Col>
    </Row>
    <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}>
        <Col><p style={{ color:bordercolor1, fontWeight:'bold', fontSize: 14}} class="text-center">{disabled ? '':'Toutes les informations sont obligatoires'}</p></Col>
      </Row>
      <Col md={{ span: 10, offset: 1 }} style={{ padding:0}}>
      <Row className="justify-content-center" style={{justifyContent:'center',alignItems:'center'}} >
    <Col xl="4" style={{justifyContent:'center',alignContent:'center',paddingInline:30, marginTop:10}} >
    <p style={{color:'#007984', fontWeight:'bold', fontSize: 14, height:30}} class="text-start">J'ai besoin d'aide pour élaborer mon questionnaire</p>
    <Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
    <Button style={{backgroundColor:bg1, borderColor:'#007984', color:col1, marginBottom:10}}  onClick={() =>{setChecked1('Oui');  setbg1('#007984'); setbg2('transparent');setcol1('white');setcol2('#007984'); Verif1('Oui',checked2)}} >Oui</Button>
    <Button style={{backgroundColor:bg2, borderColor:'#007984', color:col2}} onClick={() =>{setChecked1('Non');  setbg2('#007984'); setbg1('transparent');setcol1('#007984');setcol2('white'); Verif1('Non',checked2)}} >Non</Button>
    </Row>
      </Col>
  
      <Col xl="4"  style={{justifyContent:'center',alignItems:'center',paddingInline:30, marginTop:10}} >
        <p style={{color:'#007984', fontWeight:'bold', fontSize: 14, height:30}} class="text-start">Je souhaite utiliser <span onClick={()=>setShow1(true)} style={{textDecorationLine:'underline', cursor:'pointer', fontSize: 12}}>En savoir plus</span></p>
        <Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
    <Button style={{backgroundColor:bg3, borderColor:'#007984', color:col3, marginBottom:10}}  onClick={() =>{  setChecked2('Mon propre support'); setbg3('#007984'); setbg4('transparent');setcol3('white');setcol4('#007984'); Verif1(checked1,'Mon propre support')}}>Mon propre support</Button>
    <Button style={{backgroundColor:bg4, borderColor:'#007984', color:col4}} onClick={() =>{  setChecked2('Le support Useradventure'); setbg4('#007984'); setbg3('transparent');setcol3('#007984');setcol4('white'); Verif1(checked1,'Le support Useradventure')}}>Le support Useradventure</Button>
    </Row>
      </Col>
      
      </Row>
  </Col>
    </Row>
    {!Question1 &&<Bounce><Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
    <Button style={{backgroundColor:disabledcolor, borderColor:'rgba(254, 132, 2, 1)', borderRadius:30, height:50, width:200,  color:'white', fontWeight:'bold', marginTop:10, marginBottom:10}}  onClick={() =>{if(disabled){ Suivant1()}}}>SUIVANT</Button>
    </Row></Bounce>}
    {Question1 &&<Zoom><Row className="rounded" style={{padding:30, marginTop:15, borderColor:bordercolor2, borderWidth:2, borderStyle:'solid'}} >
    <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}>
        <Col><p style={{ color:bordercolor2, fontWeight:'bold', fontSize: 24}} class="text-center">Diffusion</p></Col>
        </Row>
        <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}>
        <Col><p style={{ color:bordercolor2, fontWeight:'bold', fontSize: 14}} class="text-center">{disabled2 ? '':'Toutes les informations sont obligatoires'}</p></Col>
      </Row>
    <Col xl="4"  style={{justifyContent:'center',alignItems:'center',paddingInline:30, marginTop:10}} >
     
      </Col>
    <Col xl="4" style={{justifyContent:'center',alignContent:'center',paddingInline:30, marginTop:10}} >
    <p style={{color:'#007984', fontWeight:'bold', fontSize: 16}} class="text-start">Nombre souhaité de répondants</p>
    <View style={{flex:5,flexDirection: "row", alignItems:'center', marginBottom:10, borderRadius:5,borderWidth:1,borderColor:'#333333'}}>
      <TouchableOpacity onPress={()=>{changeMoins();setShowParticipant(true);Verif2(Mont === 0 ? Mont : Mont - 1,checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)}}  style={{flex:1,borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <TiChevronLeft size={40} color={'#007984'} style={{cursor:'pointer'}}/>
      </TouchableOpacity>
      <View style={styles.input}>   
        {/* <Text onClick={()=>setshowModalNumber(true)} style={{ fontSize:20, color:'#007984', fontWeight:'bold', cursor:'pointer' }} >{Montant}</Text> */}
        <input
      type='text'
      name='username' 
      style={{borderWidth:0, width:80, textAlign:'center',fontSize:20, color:'#007984', fontWeight:'bold'}}
      value={Montant}
      onChange={(e)=>{setMontant(Number(e.target.value));setShowParticipant(true);Verif2(Number(e.target.value),checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)}}
    />
      </View>
      <TouchableOpacity onPress={()=>{changePlus();setShowParticipant(true);Verif2(Mont + 1,checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)}} style={{flex:1,borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <TiChevronRight size={40} color={'#007984'} style={{cursor:'pointer'}}/>
      </TouchableOpacity>
    </View>
    {ShowParticipant &&<Zoom>
    <p style={{color:'#007984', fontWeight:'bold', fontSize: 14}} class="text-start">Répondants</p>
    <Checkbox
        icon={<Icon.ImCheckmark color='#007984' size={14} />}
        name="my-input1"
        checked={checkParticipant1}
        onChange={()=>{setcheckParticipant1(!checkParticipant1);Verif2(Montant,!checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)}}
        borderColor='#007984'
        borderRadius={2}
        borderWidth={1}
        style={{ cursor: "pointer" }}
        labelStyle={{ marginLeft: 5, userSelect: "none" }}
        label='Particuliers'
      />
      <Checkbox
        icon={<Icon.ImCheckmark color='#007984' size={14} />}
        name="my-input2"
        checked={checkParticipant2}
        onChange={()=>{setcheckParticipant2(!checkParticipant2);Verif2(Montant,checkParticipant1,!checkParticipant2,checkParticipant3,checkParticipant4)}}
        borderColor='#007984'
        borderRadius={2}
        borderWidth={1}
        style={{ cursor: "pointer" }}
        labelStyle={{ marginLeft: 5, userSelect: "none" }}
        label='Professionnels'
      />
      <Checkbox
        icon={<Icon.ImCheckmark color='#007984' size={14} />}
        name="my-input3"
        checked={checkParticipant3}
        onChange={()=>{setcheckParticipant3(!checkParticipant3);Verif2(Montant,checkParticipant1,checkParticipant2,!checkParticipant3,checkParticipant4)}}
        borderColor='#007984'
        borderRadius={2}
        borderWidth={1}
        style={{ cursor: "pointer" }}
        labelStyle={{ marginLeft: 5, userSelect: "none" }}
        label='Patients'
      />
       <Checkbox
        icon={<Icon.ImCheckmark color='#007984' size={14} />}
        name="my-input2"
        checked={checkParticipant4}
        onChange={()=>{setcheckParticipant4(!checkParticipant4);Verif2(Montant,checkParticipant1,checkParticipant2,checkParticipant3,!checkParticipant4)}}
        borderColor='#007984'
        borderRadius={2}
        borderWidth={1}
        style={{ cursor: "pointer" }}
        labelStyle={{ marginLeft: 5, userSelect: "none" }}
        label='Professionnels de santé'
      />
      </Zoom>}
    </Col>
  
      
      
      <Col xl="4"  style={{justifyContent:'center',alignItems:'center',paddingInline:30, marginTop:10}} >
     
      </Col>
  
    </Row></Zoom>}
    {Question1 && !Question2 &&<Bounce><Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
    <Button style={{backgroundColor:disabledcolor2, borderColor:'rgba(254, 132, 2, 1)', borderRadius:30, height:50, width:200,  color:'white', fontWeight:'bold', marginTop:10, marginBottom:10}}  onClick={() =>{setQuestion2(true); setbordercolor2('rgba(16, 178, 200, 1)')}}>SUIVANT</Button>
    </Row></Bounce>}
    {Question2 &&<Zoom><Row className="rounded" style={{padding:30, marginTop:15, borderColor:bordercolor3, borderWidth:2, borderStyle:'solid'}} >
    <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}>
        <Col><p style={{ color:bordercolor3, fontWeight:'bold', fontSize: 24}} class="text-center">Votre contact</p></Col>
         </Row>
        <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}>  
        <Col><p style={{ color:bordercolor3, fontWeight:'bold', fontSize: 14}} class="text-center">{disabled3 ? '':'Toutes les informations sont obligatoires'}</p></Col>
      </Row>
    <Col xl="4" style={{justifyContent:'center',alignContent:'center',paddingInline:30, marginTop:10}} ></Col>
      <Col xl="4"  style={{justifyContent:'center',alignItems:'center',paddingInline:30, marginTop:10}} >
        <p style={{color:'#007984', fontWeight:'bold', fontSize: 20}} class="text-start">Mail</p>
        <Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
        <input style={{height:45}} onChange={(e)=>{setEmailTest(e.target.value);Verif3(e.target.value, disabled, disabled2)}}></input>
    </Row>
      </Col>
      <Col xl="4"  style={{justifyContent:'center',alignItems:'center',paddingInline:30, marginTop:10}}></Col>
    </Row></Zoom>}
    {Question1 && Question2 &&<Bounce><Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
    <Button style={{backgroundColor:disabledcolor3, borderColor:'rgba(254, 132, 2, 1)', borderRadius:30, height:50, width:200,  color:'white', fontWeight:'bold', marginTop:10, marginBottom:10}}  onClick={() =>{if(disabled3){Valid()}}}>Envoyer</Button>
    </Row></Bounce>}
    </Row>
    </Col>
    <ModalNumber show={showModalNumber} number1={Montant}  Valid={(text) => {setMontant(text);setshowModalNumber(false)}} Quit={(text) => {setshowModalNumber(false)}} />
    <ModalSplus show={show1} Quit={() => {setShow1(false)}} />
    <ModalFin show={showModal} Quit={() => {setshowModal(false);  window.location.reload(); history.push('/etude');window.scrollTo(0, 0)}} />
    </Zoom>
    
        
    );
  }
  
  const styles = StyleSheet.create({
    input: {
      flex:3,
      paddingHorizontal: 10,
      marginVertical: 10,
      fontSize:18,
      backgroundColor:'white',
      justifyContent: 'center', 
      alignItems: 'center'
    },
  });
  
  export default Questionnaire