import React, {useState, useMemo, useEffect,useRef} from 'react';
import {Fade, Flip, Slide} from 'react-reveal';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, InputGroup, FormControl, Button, Card } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import Wave from 'react-wavify'
import { FiArrowRightCircle } from "react-icons/fi";
import { IoClose, IoChevronForwardOutline } from "react-icons/io5";
import image_blog2 from '../src/images/image_blog2.png';
import image_blog1 from '../src/images/image_blog1.png';
import image_blog3 from '../src/images/logo_01.jpg';
import image_blog4 from '../src/images/logo_02.jpg';
import Nav from 'react-bootstrap/Nav';
import IframeResizer from 'iframe-resizer-react'
import {Linking, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground,Alert,useWindowDimensions} from 'react-native';

const Blog = ({  ChangeActive, sendColorbg, sendColor, ActiveRef}) => {

  const [show, setShow] = useState(false);
  const color1 = "#0b7482";
  const color2 = "#10b2c8";
    const [Mail, setMail] = useState('');
    const [Titre, setTitre] = useState('');
    const [Logo, setLogo] = useState('');
    const [Img1, setImg1] = useState('');
    const [Img2, setImg2] = useState('');
    const [Txt1, setTxt1] = useState('');
    const [Txt2, setTxt2] = useState('');
    const [Txt3, setTxt3] = useState('');
    const [Txt4, setTxt4] = useState('');
    const [Txt5, setTxt5] = useState('');
    const [Txt6, setTxt6] = useState('');
    const [Txt7, setTxt7] = useState('');
    const [Txt8, setTxt8] = useState('');
    const [Txt9, setTxt9] = useState('');
    const [Txt10, setTxt10] = useState('');
    const [height, setheight]= useState(0);
    const [navcolor, setnavcolor] = useState('light');
    const [bg, setbg] = useState("white");
    const {  width } = useWindowDimensions();
    const URL = 'https://www.useradventure.net/'
    console.log(Mail)

    const ChangeBG = (color, variant)=>{
      setbg(color)
      setnavcolor(variant)
    }
   
const color5 = "#0b7482";
const color4 = "#10b2c8";
  

const valider = () => {
  var position = Mail.indexOf("@");
  var reste = Mail.substr(-position);
  var posit = reste.indexOf(".");
  //alert(posit)
  if(position !== -1 && posit !== -1 ){
    fetch(`${URL}json_get_react_register_newsletter.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({mail:Mail})
    })
      .then((response) => response.json())
      .then(() => {alert('Merci pour ton inscription à la newsletter !')
      setMail('')})
      .catch((error) => console.error(error))
}else{
  alert('Email non valide')

}}


const DataEtude = [
  {id:1,
  Titre:"L'importance du test utilisateur",
  Image1:image_blog1,
  Image2:image_blog2,
  extrait:"De nombreuses entreprises veulent mettre en ligne leurs nouveaux services ou produits. Que ce soit un site web, une application...",
  text1:"De nombreuses entreprises veulent mettre en ligne leurs nouveaux services ou produits. Que ce soit un site web, une application, un dispositif médical etc, ces entreprises ont BESOIN d’avoir un retour utilisateur afin de savoir si le produit ou service répond bien aux besoins. C’est là que le test utilisateur entre en jeu.",
  text2:"Les différentes étapes du test utilisateur.",
  text3:"Plusieurs étapes sont nécessaires lors d’un test utilisateur. Dans un premier temps, cela va consister à observer le comportement de l’utilisateur sur son utilisation du service proposé. Les questions posées doivent être particulièrement réfléchies. En effet, le but est de faire attention d’influencer le moins possible l’utilisateur auquel cas le test pourrait être biaisé.",
  text4:"Ensuite, l’idée est de comprendre les difficultés que l’utilisateur a pu rencontrer lors de l'utilisation du dispositif. Nous pouvons retrouver comme critères l’ergonomie. L’utilisateur arrive t-il à utiliser le service correctement ? Sur un site web par exemple, nous allons retrouver l’expérience utilisateur : est-il visuellement agréable ? Les fonctionnalités sont-elles aisément repérées…",
  text5:"Ainsi, le test utilisateur permet d’analyser les éventuels problèmes survenus et donc d’améliorer et optimiser le service ou produit. Il met également la lumière sur les besoins de l’utilisateur, la confiance qu’il peut avoir (cela peut découler sur un achat par exemple).",
  text6:"Chez UserAdventure, nous privilégions la qualité de nos testeurs avec des critères répondant parfaitement aux besoins de l’entreprise, afin d’avoir les meilleurs retours possible.",
  text7:"Vous l’aurez compris, afin de maximiser les chances du bon développement de votre service ou produit, le test utilisateur est essentiel."
}
];

// {id:2,
//   Titre:"L'importance du logo",
//   Image1:image_blog3,
//   Image2:image_blog4,
//   extrait:"Un logo ou logotype, est la représentation graphique d'une marque ou d'une entreprise. Il permet de mettre en valeur l'image de marque...",
//   text1:`Depuis le début du 19ème siècle, le logo est devenu incontournable pour l’image et la notoriété d’une marque, d’une entreprise ou encore d’une association.`,
//   text2:`Pour commencer, qu'est-ce qu'un logo ?`,
//   text3:`Un logo ou logotype, est la représentation graphique d'une marque ou d'une entreprise. Il permet de mettre en valeur l'image de marque et surtout de la reconnaître en un instant.`,
//   text4:`Si je vous demande de dessiner de tête le logo de Nike, Ikea ou encore Apple, je suis pratiquement certain que vous serez le représenter (en oubliant le côté artistique si vous ne savez pas dessiner bien sûr).`,
//   text5:`En effet, quand on parle de Nike, on pense immédiatement à la virgule, quant à Ikea, c'est les couleurs bleu et jaune qui ressortent. Vous l'aurez compris, pour avoir un bon logo, il est important d'avoir ce petit quelque chose qui se différencie des autres.`,
//   text6:`Un logo se caractérise par le choix de ses couleurs et le choix d’une typographie. Il va symboliser l'entreprise et donc l'objectif de ce dernier va tout simplement être d'amener le public à mémoriser le logo facilement.`,
//   text7:`La couleur, un élément à ne pas négliger`,
//   text8:`En effet, lorsque l'on regarde un logo, la couleur est l'une des premières choses que l'on distingue et l'on se rappelle. Pourquoi ? Tout simplement, car il est plus facile de se rappeler d'une couleur; le cerveau utilisant la vue, que d'un élément faisant plutôt appel à la réflexion.
//   De plus, le choix de la couleur peut orienter le client sur la façon dont il va percevoir l'entreprise. Le vert peut par exemple faire penser que l'entreprise est en lien avec l'environnement. C'est d'ailleurs une couleur très controversée, car de nombreuses entreprises l'utilisent pour se donner une image "respectueuse de l'environnement." C'est ce qu'on appelle le "Greenwashing", un effet notamment utilisé par McDonald's ou encore Yves Rocher.`,
//   text9:`Comment savoir si mon logo est efficace ou non ?`,
//   text10:` Votre logo doit permettre de séduire et capter l’attention de votre audience. Il doit également valoriser l’image de votre marque et se distinguer, se démarquer de la concurrence. Ce dernier doit renforcer l'identité visuelle de votre marque.
//   Ces couleurs et typographies doivent être utilisées de la bonne façon, avec une certaine harmonie et un bon équilibre.
//   Par exemple, l'utilisation de typographies différentes rendra votre logo lourd à regarder. Il est conseillé de ne pas dépasser deux polices d’écriture.
//   Pour savoir si l'ensemble de ces éléments est respecté, vous pouvez faire appel à nous afin d'avoir un retour qualitatif sur votre charte graphique.
// `
// }

const ClicBOX = (Titre, Image1, Image2, text1, text2, text3, text4, text5, text6, text7, text8, text9, text10) => {
setShow(!show)
setTitre(Titre)
setImg1(Image1)
setImg2(Image2)
setTxt1(text1)
setTxt2(text2)
setTxt3(text3)
setTxt4(text4)
setTxt5(text5)
setTxt6(text6)
setTxt7(text7)
setTxt8(text8)
setTxt9(text9)
setTxt10(text10)
}

const retour = () => {
  setShow(!show)
}

const iframeRef = useRef(null)
  const [messageData, setMessageData] = useState()

  const onResized = data => setMessageData(data)

  const onMessage = data => {
    setMessageData(data)
    iframeRef.current.sendMessage('Hello back from the parent page')
  }
   

return (
        <div>
        
        
       
        {/* <Wave fill='white' style={{paddingInline:0, marginInline:0}} 
        paused={false}
        options={{
          height: 40,
          amplitude: 70,
          speed: 0.2,
          points: 2
        }}
  /> */}

<View style={{flexDirection: "row", alignItems: 'center', justifyContent:'space-between', background: `linear-gradient(to right,  ${color5} 0%,${color4} 100%)`, paddingHorizontal:40,paddingVertical:15}} >
<Text><Text onClick={retour} style={{color:'white', fontWeight:'bold', fontSize: 20, cursor: show ? 'pointer': undefined , textDecorationLine: show ? 'underline': undefined }}>BLOG</Text>{show && <><IoChevronForwardOutline size={30} style={{paddingBottom: 3}} color={'white'}/><Text style={{color:'white', fontWeight:'bold', fontSize: 20,textDecorationLine: 'none'}}> {Titre}</Text></>}</Text>
    </View>
  <Container style={{marginBlock:60}} fluid>

 
     
      <iframe allow="fullscreen" style={{marginBottom:0,zIndex:10}}  width="100%"
    height="1000" src="https://useradventure.blogspot.com/"/>
       
  


  {/* { !show && DataEtude && DataEtude.map((item,index) => ( 
    <Col key={index} md={{span: 6, offset: 3 }} xs={{span: 10, offset: 1 }} className="box rounded" style={{ marginBlock:10, cursor:'pointer'}} onClick={()=>ClicBOX(item.Titre,item.Image1,item.Image2,item.text1,item.text2,item.text3,item.text4,item.text5,item.text6,item.text7,item.text8,item.text9,item.text10)}>
  <Row style={{justifyContent:'center',alignItems:'center', paddingBlock:10, paddingInline:15}} >
      <Col lg="3"  className="text-lg-end text-center" style={{fontSize: 30,color: '#007F8B',paddingInline:0, fontWeight:'bold'}}>
        <Image src={item.Image1} style={{ height:200}} fluid/>
       </Col>
       <Col lg="6" className="text-start">
       <Col style={{fontSize: 26,color: '#007F8B',paddingInline:0, fontWeight:'bold'}}>
       {item.Titre}
          </Col>
          <Col style={{fontSize: 18,color: 'grey',paddingInline:0, marginTop:10}}>
          {item.extrait}
          </Col>
          </Col>
      </Row>
      </Col>  ))} */}
     {/* { show && <Col lg={{offset:3,span:6}}>
      <Col className="text-center" style={{fontSize: 30,color: '#007F8B',paddingInline:30, fontWeight:'bold'}}>{Titre}</Col>
      <Col  className="text-center" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:30}}><Image style={{ maxHeight:300}} src={Img1} fluid/></Col>
      <p  className="text-justify" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:30, textAlign:'justify'}}>{Txt1}</p>
      <p  className="text-justify" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:30, textAlign:'justify'}}>{Txt2}</p>
      <p  className="text-justify" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:30, textAlign:'justify'}}>{Txt3}</p>
      <p  className="text-justify" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:30, textAlign:'justify'}}>{Txt4}</p>
      <p  className="text-justify" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:30, textAlign:'justify'}}>{Txt5}</p>
      <Row style={{padding:0, margin:0}}>
      <Col  className="text-center" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:30}}><Image style={{ maxHeight:300}} src={Img2} fluid/></Col>
      </Row>
      <p  className="text-justify" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:30, textAlign:'justify'}}>{Txt6}</p>
      <p  className="text-justify" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:30, textAlign:'justify'}}>{Txt7}</p>
      <p  className="text-justify" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:30, textAlign:'justify'}}>{Txt8}</p>
      <p  className="text-justify" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:30, textAlign:'justify'}}>{Txt9}</p>
      <p  className="text-justify" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:30, textAlign:'justify'}}>{Txt10}</p>
      <p  className="text-end" style={{fontSize: 16,color: 'grey',paddingInline:30,marginTop:30}}>Article rédigé par Fabien Lheureux
</p>
    </Col>
    } */}
    {/* { show &&  <Container style={{marginTop:80}} fluid>
      <Row style={{justifyContent:'center',alignItems:'center'}}>
        <Col  className="text-center" style={{marginTop:-50}}>
        <LinkContainer onClick={()=>{ChangeBG("white",'light')
                sendColorbg("white")
                sendColor("black")}} style={{paddingRight:0, fontWeight:'bold',color: ActiveRef === '/etude' ? '#10b2c8' : 'grey'}}   exact to={{ pathname:"/etude", state:{ version: '1' }}}>
                    <Nav.Link>
              <a aria-expanded={ height !== 0 }
          aria-controls='example-panel'
          role="button" class="btn" id="start"  style={{background:'transparent', color:'white'}} >
                            <Row className="list" style={{background: '#10b2c8', borderRadius:35, paddingBlock:20,paddingInline:40, justifyContent:'center', fontWeight:'bold', fontSize:18}}>Lancer une étude</Row>
              </a> 
              </Nav.Link>
                </LinkContainer>
        </Col>
      </Row>
    </Container>} */}
    </Container>
    <View style={{marginTop:-200, height:140, backgroundColor:'white',zIndex:100}} fluid>
    </View>
   <Container style={{marginBlock:40, height:1, backgroundColor:'black'}} fluid> </Container>
  



        </div>
    )
}

export default Blog
