import React, {useState, useMemo} from 'react';
import { useHistory } from "react-router-dom";
import {Fade, Flip, Zoom, Bounce} from 'react-reveal';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, InputGroup, FormControl, Button } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import mailchimpFactory from '@mailchimp/mailchimp_transactional/src/index.js';
const mailchimp = mailchimpFactory("3MaihbLvVMRazJCeofwVyw");

const Autres = ({show, toggle, contact1, contact2}) => {

  const history = useHistory();
    const color1 = "#ff7500";
    const color2 = "#fe9523";
    const color3 = "#fcfcfc";
    const URL = 'https://www.useradventure.net/'
    const [Nom, setNom] = useState('');
    const [Prenom, setPrenom] = useState('');
    const [Mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [sujet, setSujet] = useState(1);
    const [msg, setMsg] = useState('');
    const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
    const [disabled, setdisabled] = useState(false);

      const ChangeMail = (event) => {
        setMail(event)
        if(event !== '' && msg !==''&& sujet !==''){
          setdisabledcolor(`linear-gradient(to right,  ${color1} 20%,${color2} 100%)`)
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };

  

      const ChangeSujet = (event) => {
        setSujet(event)
        if(event !== '' && Mail !=='' && msg !==''){
          setdisabledcolor(`linear-gradient(to right,  ${color1} 20%,${color2} 100%)`)
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };

      const ChangeMsg = (event) => {
        setMsg(event)
        if(event !== '' && Mail !=='' && sujet !==''){
          setdisabledcolor(`linear-gradient(to right,  ${color1} 20%,${color2} 100%)`)
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };

      const run = async (message) => {
        const response = await mailchimp.messages.sendTemplate({
          template_name: "UAproContact",
          "template_content": [{}],
          message: message,
        });
        console.log(response);
        setMsg('')
        setMail('')
        setSujet('')
        alert('Merci pour ton message !')
        toggle()
      };

      const validCommand1 = () => {
        var position = Mail.indexOf("@");
        var reste = Mail.substr(-position);
        var posit = reste.indexOf(".");
        //alert(posit + position + Mail )
        if(position !== -1 && posit !== -1 ){
        if(disabled){
          const message = {
            from_name: "UserAdventurePro",
            from_email: "contact@useradventure.fr",
            subject: `Autres besoins UserAdventurePro`,
            to: [
              {
                email: contact1,
                type: "to"
              },
              {
                email: contact2,
                type: "cc"
              }
            ],
            "merge_vars": [
              {
                  "rcpt": contact1,
                  "vars": [
                    {
                      "name": "EMAILPRO",
                      "content": Mail
                    },
                    {
                      "name": "TYPE",
                      "content": "Autres besoins"
                    },
                    {
                      "name": "TEST",
                      "content": sujet
                    },
                    {
                      "name": "MOD",
                      "content": msg
                    },
                  ]
              },
              {
                "rcpt": contact2,
                "vars": [
                  {
                    "name": "EMAILPRO",
                    "content": Mail
                  },
                  {
                    "name": "TYPE",
                    "content": "Autres besoins"
                  },
                  {
                    "name": "TEST",
                    "content": sujet
                  },
                  {
                    "name": "MOD",
                    "content": msg
                  },
                ]
            }
          ]
          }
        run(message) 
            
          }else{
            alert('Veuillez remplir tous les champs, Merci!')
          }
      }else{
        alert('Email non valide')
      
      }
    }

    return (
      show && <Zoom>
            <Col className="text-start"  style={{fontSize: 24,color: '#5b5b5b',paddingInline:30,marginBlock:20, fontWeight:'bold'}}>Allez, dites-nous tout !</Col>
        <Col lg={{ span: 10, offset:1  }} className="rounded" style={{padding:30, borderColor:'orange', borderWidth:2, borderStyle:'solid'}} >
         <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}>
        <Col xl="4"></Col>
        <Col xl="4"><p style={{ color:'orange', fontWeight:'bold', fontSize: 24}} class="text-center">Votre message d'amour pour nous</p></Col>
        <Col xl="4"><p style={{ color:'orange', fontWeight:'bold', fontSize: 14}} class="text-center text-xl-end">{disabled ? '':'Toutes les informations sont obligatoires'}</p></Col>
      </Row>
        <Col md={{ span: 10, offset:1  }} lg={{ span: 6, offset: 3 }} style={{marginTop:0,borderRadius:50}}>
       
          <div class="form-group" style={{marginInline:20}}>
          <label class="form-label mt-4"></label>
                            <div style={{ marginBottom:20}}>
                                <label style={{fontSize: 20,color: '#007F8B', marginBlock:10, fontWeight:'bold'}}>Sujet</label>
            <select style={{borderRadius:5, borderColor:'#10b2c8', borderWidth:1, borderStyle:'solid'}} class="form-control" onChange={e =>ChangeSujet(e.target.value)}>
                <option value="1">sujet1</option>  
                <option value="2">sujet2</option>    
                <option value="3">sujet3</option>    
            </select>
                            </div>
                            <div style={{ marginBottom:20}}>
                                <label style={{fontSize: 20,color: '#007F8B', marginBlock:10, fontWeight:'bold'}}>Message</label>
                                <textarea  rows="5" type="Prénom" value={msg} onChange={e =>ChangeMsg(e.target.value)} style={{borderRadius:5, borderColor:'#10b2c8', borderWidth:1, borderStyle:'solid', height:150, marginBottom:0}} class="form-control"/>
                            </div>  
                            <div>
                                <label style={{fontSize: 20,color: '#007F8B', marginBlock:10, fontWeight:'bold'}}>Email</label>
                                <input type="mail" value={Mail} onChange={e =>ChangeMail(e.target.value)} style={{borderRadius:5, borderColor:'#10b2c8', borderWidth:1, borderStyle:'solid'}} class="form-control"/>
                            </div>
                            <Row style={{justifyContent:'center',alignItems:'center',marginTop:30}}>
        <Col  className="text-center" >
       
                            <a role="button" class="btn"  onClick={()=>validCommand1()} style={{background:'transparent', color:'white'}} >
                            <Row style={{background: disabledcolor, borderRadius:35, paddingBlock:20,paddingInline:50, justifyContent:'center', fontWeight:'bold', fontSize:18}}>Envoyer</Row>
                            </a> 
              </Col>
                    </Row>

                     
                  </div>
        </Col>
        </Col>
        </Zoom>
    )
}

export default Autres
