import React, {useState, useMemo, useEffect} from 'react';
import './App.css';
import {LinkContainer} from 'react-router-bootstrap';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, Button, ButtonGroup, ToggleButton, Form, DropdownButton, Dropdown  } from 'react-bootstrap';
import {Linking, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground,Alert} from 'react-native';
import {Fade, Flip, Zoom, Bounce} from 'react-reveal';
import * as Icon from "react-icons/im";
import Checkbox from "react-custom-checkbox";
import { TiChevronLeft, TiChevronRight } from "react-icons/ti";
import ModalSplus from './components/ModalSplus'
import ModalFin from './components/ModalFin'
import {useHistory } from "react-router-dom";
import axios from 'axios';
import $ from 'jquery';
import mailchimpFactory from '@mailchimp/mailchimp_transactional/src/index.js';
const mailchimp = mailchimpFactory("3MaihbLvVMRazJCeofwVyw");


const Testuser = ({show, toggle, contact1, contact2}) => {
const history = useHistory();
const [Test, setTest]= useState(false);
const [Test1, setTest1]= useState(false);
const [Test2, setTest2]= useState(false);
const [ShowVille, setShowVille]= useState(false);
const [Ville, setVille]= useState('');
const [ShowQui, setShowQui]= useState(false);
const [show1, setShow1] = useState(false);
const [showModal, setshowModal] = useState(false);
const [ShowParticipant, setShowParticipant]= useState(false);
const [ShowParticipant2, setShowParticipant2]= useState(false);
const [ShowParticipant3, setShowParticipant3]= useState(false);
const [ShowParticipant4, setShowParticipant4]= useState(false);
const [ShowParticipant5, setShowParticipant5]= useState(false);
const [ShowParticipant6, setShowParticipant6]= useState(false);
const [EmailTest, setEmailTest]= useState('');
const [modalVisible3, setModalVisible3] = useState(false);
const URL_ville =  "https://vicopo.selfbuild.fr/cherche/"
const [dataVille, setDataVille] = useState([]);

useEffect(() => {
    setTest(true)
}, []);


const [checked1, setChecked1] = useState('');
  const [bg1, setbg1] = useState('transparent');
  const [bg2, setbg2] = useState('transparent');
  const [col1, setcol1] = useState('#007984');
  const [col2, setcol2] = useState('#007984');


  const [checked2, setChecked2] = useState('');
  const [checked3, setChecked3] = useState('');
  const [bg3, setbg3] = useState('transparent');
  const [bg4, setbg4] = useState('transparent');
  const [col3, setcol3] = useState('#007984');
  const [col4, setcol4] = useState('#007984');

  const [checked4, setChecked4] = useState('');
  const [checked40, setChecked40] = useState(false);
  const [bg30, setbg30] = useState('transparent');
  const [bg40, setbg40] = useState('transparent');
  const [col30, setcol30] = useState('#007984');
  const [col40, setcol40] = useState('#007984');
  const [bg5, setbg5] = useState('transparent');
  const [bg6, setbg6] = useState('transparent');
  const [col5, setcol5] = useState('#007984');
  const [col6, setcol6] = useState('#007984');

  const [bordercolor1, setbordercolor1] = useState('rgba(254, 132, 2, 1)');
  const [bordercolor2, setbordercolor2] = useState('rgba(254, 132, 2, 1)');
  const [bordercolor3, setbordercolor3] = useState('rgba(254, 132, 2, 1)');

  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);

  const [disabledcolor2, setdisabledcolor2] = useState('#DCDCDC');
  const [disabled2, setdisabled2] = useState(false);

  const [disabledcolor3, setdisabledcolor3] = useState('#DCDCDC');
  const [disabled3, setdisabled3] = useState(false);
  
  const [Montant, setMontant] = useState(0);
  const [Montant1, setMontant1] = useState(0);
  const [Montant2, setMontant2] = useState(0);
  const [Mont, setMont] = useState(0);
  const [Mont1, setMont1] = useState(0);
  const [Mont2, setMont2] = useState(0);

  const changeMoins = () => {
    if(Montant > 0){
      setMontant(Montant - 1)
    }
  }

  const changePlus = () => {
  
      setMontant(Montant + 1)
   
  }

  const changeMoins2 = () => {
    if(Montant2 > 0){
      setMontant2(Montant2 - 1)
    }
  }

  const changePlus2 = () => {
  
      setMontant2(Montant2 + 1)
   
  }

  const changeMoins1 = (x) => {
    if(x > 0){
      setMontant1(x - 1)
    }
      if(x===1){setShowParticipant2(false) }
      else if(x===2){setShowParticipant3(false) }
      else if(x===3){setShowParticipant4(false) }
      else if(x===4){setShowParticipant5(false) }
      else if(x===5){setShowParticipant6(false) }
    
  }

  const changePlus1 = (x) => {
    if(x < 5){
      setMontant1(x + 1)
      if(x===0){setShowParticipant2(true) }
      else if(x===1){setShowParticipant3(true) }
      else if(x===2){setShowParticipant4(true) }
      else if(x===3){setShowParticipant5(true) }
      else if(x===4){setShowParticipant6(true) }
    }
  }

  const [checkParticipant1, setcheckParticipant1] = useState(false);
  const [checkParticipant2, setcheckParticipant2] = useState(false);
  const [checkParticipant3, setcheckParticipant3] = useState(false);
  const [checkParticipant4, setcheckParticipant4] = useState(false);

  const [S1, setS1] = useState('30');
  const [S2, setS2] = useState('30');
  const [S3, setS3] = useState('30');
  const [S4, setS4] = useState('30');
  const [S5, setS5] = useState('30');


  const Suivant1 = () => {
  setTest1(true);
  setbordercolor1('rgba(16, 178, 200, 1)')
}

const Verif1 = (checked1,checked2,checked3,checked4,Ville) => {
  setdisabledcolor('#DCDCDC')
  setdisabled(false)
  setbordercolor1('rgba(254, 132, 2, 1)')
  Verif3(EmailTest, false, disabled2)
  console.log('checked',checked1,checked2,checked3,checked4,Ville)
  if((checked1 === "en ligne")||(checked1 !== "en ligne" && Ville!== "")){
    if((checked2 === "Non")||(checked2 !== "Non" && checked3 !== "")){
      if((checked4 !== '' && checked4 === 'en groupe')){
        Verif2(Mont,Mont1,Mont2,'en groupe',checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)
    setdisabledcolor('rgba(254, 132, 2, 1)')
    setdisabled(true)
    setbordercolor1('rgba(16, 178, 200, 1)')
    Verif3(EmailTest, true, disabled2)
  }else if((checked4 !== '' && checked4 === 'individuelle')){
    Verif2(Mont,Mont1,Mont2,'individuelle',checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)
      setdisabledcolor('rgba(254, 132, 2, 1)')
      setdisabled(true)
      setbordercolor1('rgba(16, 178, 200, 1)')
      Verif3(EmailTest, true, disabled2)
  }}}
   
}

const Verif2 = (MontC,MontC1,MontC2, sess,p1,p2,p3,p4) => {
  setMont(MontC)
  setMont1(MontC1)
  setMont2(MontC2)
  setdisabledcolor2('#DCDCDC')
  setdisabled2(false)
  setbordercolor2('rgba(254, 132, 2, 1)')
  Verif3(EmailTest, disabled, false)
  console.log('checked',MontC,MontC1,MontC2)
  console.log('montant2',Montant2)
  if(sess === 'en groupe'){
  if(MontC !== 0 && MontC1 !== 0 && MontC2 !== 0 && ( p1 ||  p2 || p3 || p4 )){
    console.log('la1',MontC,MontC1,MontC2)
    setdisabledcolor2('rgba(254, 132, 2, 1)')
    setdisabled2(true)
    setbordercolor2('rgba(16, 178, 200, 1)')
    Verif3(EmailTest, disabled, true)
  }}else{
    if(MontC !== 0 && MontC1 !== 0 && ( p1 ||  p2 || p3 || p4 )){
      console.log('la2',MontC,MontC1,MontC2)
      setdisabledcolor2('rgba(254, 132, 2, 1)')
      setdisabled2(true)
      setbordercolor2('rgba(16, 178, 200, 1)')
      Verif3(EmailTest, disabled, true)
    }
  }
  
}

  const Verif3 = (mailC, D, D2) => {
    console.log('mailC',mailC)
    console.log('disabled2',D2)
    console.log('disabled',D)
    setdisabledcolor3('#DCDCDC')
    setdisabled3(false)
    setbordercolor3('rgba(254, 132, 2, 1)')
    if(mailC !== ""){
      setbordercolor3('rgba(16, 178, 200, 1)')
    if(D2 &&  D){
    
    setdisabledcolor3('rgba(254, 132, 2, 1)')
      setdisabled3(true)
  
    }
  
  }
  }

  const titre = checked4 === 'en groupe' ? 'Nombre de participants par groupe':'Nombre total de participants'


  const run = async (message) => {
    setshowModal(true)
    const response = await mailchimp.messages.sendTemplate({
      template_name: "UApro",
      "template_content": [ {}],
      message: message,
    });
    console.log(response);
  };

  const Valid = () => {

    const p1 = checkParticipant1 ? 'Particuliers ,':''
    const p2 = checkParticipant2 ? 'Professionnels ,':''
    const p3 = checkParticipant3 ? 'Patients ,':''
    const p4 = checkParticipant4 ? 'Professionnels de santé':''
    const textVille = Ville !=="" ? `sur ${Ville}`:''
    const textQui = checked3 !=="" ? `(${checked3})`:''
    const TS1 = Montant1 === 1 ? `(${S1})`: Montant1 === 2 ? `(${S1}, ${S2})`: Montant1 === 3 ? `(${S1}, ${S2}, ${S3})`: Montant1 === 4 ? `(${S1}, ${S2}, ${S3}, ${S4})`: `(${S1}, ${S2}, ${S3}, ${S4}, ${S5})`
    const GR = checked4 === 'en groupe' ? `(${Montant2})`: ''
    
    const message = {
      from_name: "UserAdventurePro",
      from_email: "contact@useradventure.fr",
      subject: `Commande UserAdventurePro`,
      to: [
        {
          email: contact1,
          type: "to"
        },
        {
          email: contact2,
          type: "cc"
        }
      ],
      "merge_vars": [
        {
            "rcpt": contact1,
            "vars": [
              {
                "name": "EMAILPRO",
                "content": EmailTest
              },
              {
                "name": "TYPE",
                "content": "Test Utilisateur"
              },
              {
                "name": "TEST",
                "content": checked1
              },
              {
                "name": "VILLE",
                "content": textVille
              },
              {
                "name": "MOD",
                "content": checked2
              },
              {
                "name": "QUI",
                "content": textQui
              },
              {
                "name": "SESSION",
                "content": checked4
              },
              {
                "name": "TITRE",
                "content": titre
              },
              {
                "name": "NBP",
                "content": `${Montant}`
              },
              {
                "name": "P",
                "content": `(${p1}${p2}${p3}${p4})`
              },
              {
                "name": "NBS",
                "content": Montant1
              },
              {
                "name": "NBG",
                "content": GR
              },
              {
                "name": "TS1",
                "content": TS1
              },
            ]
        },
        {
          "rcpt": contact2,
          "vars": [
            {
              "name": "EMAILPRO",
              "content": EmailTest
            },
            {
              "name": "TYPE",
              "content": "Test Utilisateur"
            },
            {
              "name": "TEST",
              "content": checked1
            },
            {
              "name": "VILLE",
              "content": textVille
            },
            {
              "name": "MOD",
              "content": checked2
            },
            {
              "name": "QUI",
              "content": textQui
            },
            {
              "name": "SESSION",
              "content": checked4
            },
            {
              "name": "TITRE",
              "content": titre
            },
            {
              "name": "NBP",
              "content": `${Montant}`
            },
            {
              "name": "P",
              "content": `(${p1}${p2}${p3}${p4})`
            },
            {
              "name": "NBS",
              "content": Montant1
            },
            {
              "name": "NBG",
              "content": GR
            },
            {
              "name": "TS1",
              "content": TS1
            },
          ]
      }
    ]
    }
  run(message)
  }

  const select1 = {
    height:45,
    borderWidth:1,
    borderColor:'#333333', 
    borderRadius:5, 
    backgroundColor:'transparent',
    fontSize:20, 
    color:'#007984', 
    fontWeight:'bold'
  }

  const ChangePostal = (text) => {
    dataVille.length = 0
   setModalVisible3(false)
   axios.get(`${URL_ville}${text}`).then(function(response){
       response.data.cities.map((item, index) => {
         dataVille.push({ key: index++,"label":item.city})
        // setData([...data,{ key: index++,"label":item.city}])
        console.log(dataVille)
       })
      
        })
       .catch(function (error) {
         // handle error
         console.log(error);
       })
       .finally(() =>  {setModalVisible3(true)})
  
     }
  
 
  return (
    show && <> <Zoom>
      <Col md={{ span: 10, offset: 1 }} style={{ padding:40}}>
      <Row className="justify-content-center" style={{justifyContent:'center',alignItems:'center'}} >
      <p style={{paddingInline:20, color:'#10b2c8', fontWeight:'bold', fontSize: 24}} class="text-center">TEST UTILISATEUR</p>
      <Row className="rounded" style={{padding:30, borderColor:bordercolor1, borderWidth:2, borderStyle:'solid'}} >
      <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}>
        <Col><p style={{ color:bordercolor1, fontWeight:'bold', fontSize: 24}} class="text-center">Modalités</p></Col>
        </Row>
        <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}>
        <Col><p style={{ color:bordercolor1, fontWeight:'bold', fontSize: 14}} class="text-center">{disabled ? '':'Toutes les informations sont obligatoires'}</p></Col>
      </Row>
      <Col xl="4" style={{justifyContent:'center',alignContent:'center',paddingInline:30, marginTop:10}} >
      <p style={{color:'#007984', fontWeight:'bold', fontSize: 16}} class="text-start">Les tests se déroulent</p>
      <Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
      <Button style={{backgroundColor:bg1, borderColor:'#007984', color:col1, marginBottom:10}}  onClick={() =>{setChecked1('en présentiel'); setbg1('#007984'); setbg2('transparent');setcol1('white');setcol2('#007984'); setShowVille(true);Verif1('en présentiel',checked2,checked3,checked4,Ville)}} >En présentiel</Button>
      <Button style={{backgroundColor:bg2, borderColor:'#007984', color:col2}} onClick={() =>{setChecked1('en ligne');  setbg2('#007984'); setbg1('transparent');setcol1('#007984');setcol2('white'); setShowVille(false);Verif1('en ligne',checked2,checked3,checked4,Ville)}} >En ligne</Button>
      </Row>
      {ShowVille && <Zoom>
        <Form.Group style={{marginTop: 10}}>
        <Form.Label style={{color:'#007984'}} >Ville</Form.Label>
        <Form.Control
      type='text'
      autoComplete='off'
      name='username' 
      placeholder='Ville' 
      value={Ville}
      onChange={(e)=>{setVille(e.target.value);ChangePostal(e.target.value); Verif1(checked1,checked2,checked3,checked4,e.target.value)}}
    />
    </Form.Group>
       <ListGroup>
       {modalVisible3 && dataVille.map((item, index) => (
      <ListGroup.Item action variant="info" onClick={()=>{setVille(item.label); setModalVisible3(false)}}>
      {item.label}
      </ListGroup.Item>
     ))} 
     </ListGroup>
     </Zoom>}
       
        </Col>
    
        <Col xl="4"  style={{justifyContent:'center',alignItems:'center',paddingInline:30, marginTop:10}} >
          <p style={{color:'#007984', fontWeight:'bold', fontSize: 16}} class="text-start">Le test est modéré ? <span onClick={()=>setShow1(true)} style={{textDecorationLine:'underline', cursor:'pointer', fontSize: 12}}>En savoir plus</span></p>
          <Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
      <Button style={{backgroundColor:bg3, borderColor:'#007984', color:col3, marginBottom:10}}  onClick={() =>{ setChecked2('Oui'); setbg3('#007984'); setbg4('transparent');setcol3('white');setcol4('#007984'); setShowQui(true);Verif1(checked1,'Oui',checked3,checked4,Ville)}}>Oui</Button>
      <Button style={{backgroundColor:bg4, borderColor:'#007984', color:col4}} onClick={() =>{ setChecked2('Non'); setbg4('#007984'); setbg3('transparent');setcol3('#007984');setcol4('white'); setShowQui(false);Verif1(checked1,'Non',checked3,checked4,Ville)}}>Non</Button>
      </Row>
      {ShowQui && <Zoom>
        <Form.Group style={{marginBlock: 10}}>
        <Form.Label style={{color:'#007984'}} >Par qui ?</Form.Label>
        <Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
      <Button style={{backgroundColor:bg30, borderColor:'#007984', color:col30, marginBottom:10}}  onClick={() =>{ setChecked3('Moi ou mon équipe'); setChecked40(false); setbg30('#007984'); setbg40('transparent');setcol30('white');setcol40('#007984');Verif1(checked1,checked2,'Moi ou mon équipe',checked4,Ville)}}>Moi ou mon équipe</Button>
      <Button style={{backgroundColor:bg40, borderColor:'#007984', color:col40}} onClick={() =>{ setChecked3('Je souhaite déléguer la modération'); setChecked40(false); setbg40('#007984'); setbg30('transparent');setcol30('#007984');setcol40('white');Verif1(checked1,checked2,'Je souhaite déléguer la modération',checked4,Ville)}}>Je souhaite déléguer la modération</Button>
      </Row>
      </Form.Group>
      </Zoom>}
        </Col>
        
        <Col xl="4"  style={{justifyContent:'center',alignItems:'center',paddingInline:30, marginTop:10}} >
          <p style={{color:'#007984', fontWeight:'bold', fontSize: 16}} class="text-start">Les sessions de test sont</p>
          <Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
      <Button style={{backgroundColor:bg5, borderColor:'#007984', color:col5, marginBottom:10}}  onClick={() =>{ setChecked4('individuelle'); setbg5('#007984'); setbg6('transparent');setcol5('white');setcol6('#007984');Verif1(checked1,checked2,checked3,'individuelle',Ville)}}>Individuelle</Button>
      <Button style={{backgroundColor:bg6, borderColor:'#007984', color:col6}} onClick={() =>{ setChecked4('en groupe'); setbg6('#007984'); setbg5('transparent');setcol5('#007984');setcol6('white');Verif1(checked1,checked2,checked3,'en groupe',Ville)}}>En groupe</Button>
      </Row>
        </Col>
    
      </Row>
      {!Test1 &&<Bounce><Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
      <Button style={{backgroundColor:disabledcolor, borderColor:'rgba(254, 132, 2, 1)', borderRadius:30, height:50, width:200,  color:'white', fontWeight:'bold', marginTop:10, marginBottom:10}}  onClick={() =>{if(disabled){ Suivant1()}}}>SUIVANT</Button>
      </Row></Bounce>}
      {Test1 &&<Zoom><Row className="rounded" style={{padding:30, marginTop:15, borderColor:bordercolor2, borderWidth:2, borderStyle:'solid'}} >
      <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}>
        <Col><p style={{ color:bordercolor2, fontWeight:'bold', fontSize: 24}} class="text-center">Participants</p></Col>
        </Row>
        <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}>
        <Col><p style={{ color:bordercolor2, fontWeight:'bold', fontSize: 14}} class="text-center">{disabled2 ? '':'Toutes les informations sont obligatoires'}</p></Col>
      </Row>
      { checked4 === 'en groupe' &&  <Col xl="4"  style={{justifyContent:'center',alignItems:'center',paddingInline:30, marginTop:10}} >
      <p style={{color:'#007984', fontWeight:'bold', fontSize: 16}} class="text-start">Nombre de groupe</p>
          <View style={{flex:5,flexDirection: "row", alignItems:'center', marginBottom:10, borderRadius:5,borderWidth:1,borderColor:'#333333'}}>
        <TouchableOpacity onPress={()=>{changeMoins2(Montant2);Verif2(Mont ,Mont1, Mont2 === 0 ? Mont2 : Mont2 - 1, checked4,checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)}}  style={{flex:1,borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
          <TiChevronLeft size={40} color={'#007984'} style={{cursor:'pointer'}}/>
        </TouchableOpacity>
        <View style={styles.input}>   
          {/* <Text style={{ fontSize:20, color:'#007984', fontWeight:'bold' }} >{Montant2}</Text> */}
          <input
      type='text'
      name='username' 
      style={{borderWidth:0, width:80, textAlign:'center',fontSize:20, color:'#007984', fontWeight:'bold'}}
      value={Montant2}
      onChange={(e)=>{setMontant2(Number(e.target.value));Verif2(Mont ,Mont1, Number(e.target.value), checked4,checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)}}
    />
        </View>
        <TouchableOpacity onPress={()=>{changePlus2(Montant2);Verif2( Mont ,Mont1, Mont2 + 1, checked4,checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)}} style={{flex:1,borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
          <TiChevronRight size={40} color={'#007984'} style={{cursor:'pointer'}}/>
        </TouchableOpacity>
      </View>
       </Col>}
      <Col xl="4" style={{justifyContent:'center',alignContent:'center',paddingInline:30, marginTop:10}} >
      <p style={{color:'#007984', fontWeight:'bold', fontSize: 16}} class="text-start">{titre}</p>
      <View style={{flex:5,flexDirection: "row", alignItems:'center', marginBottom:10, borderRadius:5,borderWidth:1,borderColor:'#333333'}}>
        <TouchableOpacity onPress={()=>{changeMoins();setShowParticipant(true);Verif2(Mont === 0 ? Mont : Mont - 1,Mont1, Mont2, checked4,checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)}}  style={{flex:1,borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
          <TiChevronLeft size={40} color={'#007984'} style={{cursor:'pointer'}}/>
        </TouchableOpacity>
        <View style={styles.input}>   
          {/* <Text style={{ fontSize:20, color:'#007984', fontWeight:'bold' }} >{Montant}</Text> */}
           <input
      type='text'
      name='username' 
      style={{borderWidth:0, width:80, textAlign:'center',fontSize:20, color:'#007984', fontWeight:'bold'}}
      value={Montant}
      onChange={(e)=>{setShowParticipant(true);setMontant(Number(e.target.value));Verif2(Number(e.target.value) ,Mont1, Mont2, checked4,checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)}}
    />
        </View>
        <TouchableOpacity onPress={()=>{changePlus();setShowParticipant(true);Verif2(Mont + 1,Mont1, Mont2, checked4,checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)}} style={{flex:1,borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
          <TiChevronRight size={40} color={'#007984'} style={{cursor:'pointer'}}/>
        </TouchableOpacity>
      </View>
      {ShowParticipant &&<Zoom>
      <p style={{color:'#007984', fontWeight:'bold', fontSize: 14, paddingTop:10}} class="text-start">Participants</p>
      <Checkbox
        icon={<Icon.ImCheckmark color='#007984' size={14} />}
        name="my-input1"
        checked={checkParticipant1}
        onChange={()=>{setcheckParticipant1(!checkParticipant1);Verif2(Mont,Mont1, Mont2, checked4,!checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)}}
        borderColor='#007984'
        borderRadius={2}
        borderWidth={1}
        size={18}
        style={{ cursor: "pointer" }}
        labelStyle={{ marginLeft: 5, userSelect: "none" }}
        label='Particuliers'
      />
      <Checkbox
        icon={<Icon.ImCheckmark color='#007984' size={14} />}
        name="my-input2"
        checked={checkParticipant2}
        onChange={()=>{setcheckParticipant2(!checkParticipant2);Verif2(Mont,Mont1, Mont2, checked4,checkParticipant1,!checkParticipant2,checkParticipant3,checkParticipant4)}}
        borderColor='#007984'
        borderRadius={2}
        borderWidth={1}
        style={{ cursor: "pointer" }}
        labelStyle={{ marginLeft: 5, userSelect: "none" }}
        label='Professionnels'
      />
      <Checkbox
        icon={<Icon.ImCheckmark color='#007984' size={14} />}
        name="my-input3"
        checked={checkParticipant3}
        onChange={()=>{setcheckParticipant3(!checkParticipant3);Verif2(Mont,Mont1, Mont2, checked4,checkParticipant1,checkParticipant2,!checkParticipant3,checkParticipant4)}}
        borderColor='#007984'
        borderRadius={2}
        borderWidth={1}
        style={{ cursor: "pointer" }}
        labelStyle={{ marginLeft: 5, userSelect: "none" }}
        label='Patients'
      />
       <Checkbox
        icon={<Icon.ImCheckmark color='#007984' size={14} />}
        name="my-input4"
        checked={checkParticipant4}
        onChange={()=>{setcheckParticipant4(!checkParticipant4);Verif2(Mont,Mont1, Mont2, checked4,checkParticipant1,checkParticipant2,checkParticipant3,!checkParticipant4)}}
        borderColor='#007984'
        borderRadius={2}
        borderWidth={1}
        style={{ cursor: "pointer" }}
        labelStyle={{ marginLeft: 5, userSelect: "none" }}
        label='Professionnels de santé'
      />
               
      </Zoom>}
      </Col>
    
        <Col xl="4"  style={{justifyContent:'center',alignItems:'center',paddingInline:30, marginTop:10}} >
          <p style={{color:'#007984', fontWeight:'bold', fontSize: 16}} class="text-start">Nombre de session par participants</p>
          <View style={{flex:5,flexDirection: "row", alignItems:'center', marginBottom:10, borderRadius:5,borderWidth:1,borderColor:'#333333'}}>
        <TouchableOpacity onPress={()=>{changeMoins1(Montant1);Verif2(Mont,Mont1 === 0 ? Mont1 : Mont1 - 1, Mont2, checked4,checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)}}  style={{flex:1,borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
          <TiChevronLeft size={40} color={'#007984'} style={{cursor:'pointer'}}/>
        </TouchableOpacity>
        <View style={styles.input}>   
          <Text style={{ fontSize:20, color:'#007984', fontWeight:'bold', paddingVertical:5 }} >{Montant1}</Text>
        </View>
        <TouchableOpacity onPress={()=>{changePlus1(Montant1);Verif2(Mont,Mont1 === 5 ? Mont1 : Mont1 + 1, Mont2, checked4,checkParticipant1,checkParticipant2,checkParticipant3,checkParticipant4)}} style={{flex:1,borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
          <TiChevronRight size={40} color={'#007984'} style={{cursor:'pointer'}}/>
        </TouchableOpacity>
      </View>
      {ShowParticipant2 && <Zoom>
        <p style={{color:'#007984', fontWeight:'bold', fontSize: 14, paddingTop:10}} class="text-start">Durée session 1</p>
        <Col xl="6"   >
        <Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
            <select style={select1} onChange={(e)=>{setS1(e.target.value)}}>
                <option value="15">15 min</option> 
                <option value="30">30 min</option>  
                <option value="45">45 min</option>    
                <option value="60">1 H</option>
                <option value="90">1 H 30</option>
                <option value="120">2 H</option>
                <option value="150">2 H 30</option>
                <option value="180">3 H</option>    
            </select>
            </Row>
            </Col>
      </Zoom>}
      {ShowParticipant3 && <Zoom>
        <p style={{color:'#007984', fontWeight:'bold', fontSize: 14,marginTop:20}} class="text-start">Durée session 2</p>
        <Col xl="6"   >
        <Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
            <select style={select1} onChange={(e)=>{setS2(e.target.value)}}>
            <option value="15">15 min</option> 
                <option value="30">30 min</option>  
                <option value="45">45 min</option>    
                <option value="60">1 H</option>
                <option value="90">1 H 30</option>
                <option value="120">2 H</option>
                <option value="150">2 H 30</option>
                <option value="180">3 H</option>      
            </select>
            </Row>
            </Col>
      </Zoom>}
      {ShowParticipant4 && <Zoom>
        <p style={{color:'#007984', fontWeight:'bold', fontSize: 14, marginTop:20}} class="text-start">Durée session 3</p>
        <Col xl="6"   >
        <Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
            <select style={select1} onChange={(e)=>{setS3(e.target.value)}}>
            <option value="15">15 min</option> 
                <option value="30">30 min</option>  
                <option value="45">45 min</option>    
                <option value="60">1 H</option>
                <option value="90">1 H 30</option>
                <option value="120">2 H</option>
                <option value="150">2 H 30</option>
                <option value="180">3 H</option>     
            </select>
            </Row>
            </Col>
      </Zoom>}
      {ShowParticipant5 && <Zoom>
        <p style={{color:'#007984', fontWeight:'bold', fontSize: 14, marginTop:20}} class="text-start">Durée session 4</p>
        <Col xl="6"   >
        <Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
            <select style={select1} onChange={(e)=>{setS4(e.target.value)}}>
            <option value="15">15 min</option> 
                <option value="30">30 min</option>  
                <option value="45">45 min</option>    
                <option value="60">1 H</option>
                <option value="90">1 H 30</option>
                <option value="120">2 H</option>
                <option value="150">2 H 30</option>
                <option value="180">3 H</option>      
            </select>
            </Row>
            </Col>
      </Zoom>}
      {ShowParticipant6 && <Zoom>
        <p style={{color:'#007984', fontWeight:'bold', fontSize: 14, marginTop:20}} class="text-start">Durée session 5</p>
        <Col xl="6"   >
        <Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
            <select style={select1} onChange={(e)=>{setS5(e.target.value)}}>
                <option value="15">15 min</option> 
                <option value="30">30 min</option>  
                <option value="45">45 min</option>    
                <option value="60">1 H</option>
                <option value="90">1 H 30</option>
                <option value="120">2 H</option>
                <option value="150">2 H 30</option>
                <option value="180">3 H</option>    
            </select>
            </Row>
            </Col>
      </Zoom>}
        </Col>
        
        { checked4 !== 'en groupe' &&  <Col xl="4"  style={{justifyContent:'center',alignItems:'center',paddingInline:30, marginTop:10}} >
       
        </Col>}
    
      </Row></Zoom>}
      {Test1 && !Test2 &&<Bounce><Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
      <Button style={{backgroundColor:disabledcolor2, borderColor:'rgba(254, 132, 2, 1)', borderRadius:30, height:50, width:200,  color:'white', fontWeight:'bold', marginTop:10, marginBottom:10}}  onClick={() =>{if(disabled2){setTest2(true); setbordercolor2('rgba(16, 178, 200, 1)')}}}>SUIVANT</Button>
      </Row></Bounce>}
      {Test2 &&<Zoom><Row className="rounded" style={{padding:30, marginTop:15, borderColor:bordercolor3, borderWidth:2, borderStyle:'solid'}} >
      <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}>
        <Col><p style={{ color:bordercolor3, fontWeight:'bold', fontSize: 24}} class="text-center">Votre contact</p></Col>
         </Row>
        <Row style={{justifyContent:'center',alignItems:'center', padding: 0, margin: 0}}>  
        <Col><p style={{ color:bordercolor3, fontWeight:'bold', fontSize: 14}} class="text-center">{disabled3 ? '':'Toutes les informations sont obligatoires'}</p></Col>
      </Row>
      <Col xl="4" style={{justifyContent:'center',alignContent:'center',paddingInline:30, marginTop:10}} ></Col>
        <Col xl="4"  style={{justifyContent:'center',alignItems:'center',paddingInline:30, marginTop:10}} >
          <p style={{color:'#007984', fontWeight:'bold', fontSize: 20}} class="text-start">Mail</p>
          <Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
          <input style={{height:45}} onChange={(e)=>{setEmailTest(e.target.value);Verif3(e.target.value, disabled, disabled2)}}></input>
      </Row>
        </Col>
        <Col xl="4"  style={{justifyContent:'center',alignItems:'center',paddingInline:30, marginTop:10}}></Col>
      </Row></Zoom>}
      {Test1 && Test2 &&<Bounce><Row style={{justifyContent:'center',alignContent:'center',margin:0}} >
      <Button style={{backgroundColor:disabledcolor3, borderColor:'rgba(254, 132, 2, 1)', borderRadius:30, height:50, width:200,  color:'white', fontWeight:'bold', marginTop:10, marginBottom:10}}  onClick={() =>{if(disabled3){Valid()}}}>Envoyer</Button>
      </Row>
      </Bounce>}
      <Row className="justify-content-center" style={{justifyContent:'center',alignItems:'center'}} >
      <a onClick={() => { history.push('/contact');window.scrollTo(0, 0)}}  style={{color:'#10b2c8', fontSize: 14, cursor:'pointer'}} class="text-center">Contactez-nous directement</a>
      </Row>
      </Row>
      </Col>
      <ModalSplus show={show1} Quit={() => {setShow1(false)}} />
      <ModalFin show={showModal} Quit={() => {setshowModal(false);  window.location.reload(); history.push('/etude');window.scrollTo(0, 0)}} />
      </Zoom>
       <Col style={{height:1, backgroundColor:'black'}}></Col>
       </>
  )
}

const styles = StyleSheet.create({
  input: {
    flex:3,
    paddingHorizontal: 10,
    marginVertical: 10,
    fontSize:18,
    backgroundColor:'white',
    justifyContent: 'center', 
    alignItems: 'center'
  },
 
});

export default Testuser