import React, {useState, useEffect, useRef,useMemo} from 'react';
import { Text, View } from 'react-native';
import { Route, useParams, useLocation} from "react-router-dom";
import Home from './Home';
import FAQ from './FAQ';
import Blog from './Blog';
import Contact from './Contact';
import Mention from './Mention';
import PPDP from './PPDP';
import Etude from './Etude';
import Menu from './components/Menu'
import Footer from './components/Footer';
import CookieBanner from 'react-cookie-banner';
import CookieConsent from "react-cookie-consent";
import Modal1 from './components/Modal1'
import Modal2 from './components/Modal2'
import Modal3 from './components/Modal3'
import countryList from 'react-select-country-list'
import {LinkContainer} from 'react-router-bootstrap';
import axios from 'axios';


const Site = () => {

  let { url } = useParams();
  console.log('url',url)

    const [colorbg, setcolorbg] = useState("white");
    const [color, setcolor] = useState("black");
    const [Active, setActive] = useState('/');
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [Nom, setNom] = useState('');
    const [Prenom, setPrenom] = useState('');
    const [Mail, setMail] = useState('');
    const [sexe, setSexe] = useState('');
    const [phone, setPhone] = useState('');
    const [rue, setRue] = useState('');
    const [numRue, setnumRue] = useState('');
    const [compAd, setcompAd] = useState('');
    const [codepostale, setCodepostale] = useState('');
    const [ville, setVille] = useState('');
    const [value, setValue] = useState('FR')
    const [value2, setValue2] = useState('FR')
    const [Pays, setPays] = useState('France')
    const [national, setNational] = useState('France')
    const {overwrite, getName } = require('country-list');
    const [startDate, setStartDate] = useState('');
    const [BDate, setBDate] = useState('');
    const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
    const [disabled, setdisabled] = useState(false);
    const [disabledcolor2, setdisabledcolor2] = useState('#DCDCDC');
    const [disabled2, setdisabled2] = useState(false);
    const URL = 'https://www.useradventure.net/'
    const [checked, setChecked] = useState(false);
    const [data, setData] = useState([]);
    const URL_ville =  "https://vicopo.selfbuild.fr/cherche/"

    const location = useLocation();
  
    console.log('hash', location.hash);
    console.log('pathname', location.pathname);
    console.log('search', location.search);

    React.useEffect(() => {
     setActive(location.pathname)
    }, [location]);

    const handleChange = () => {
      setChecked(!checked);
      if(!checked === true && phone !=='' && rue !=='' && codepostale !=='' && ville !=='' && numRue !=='' && Pays !==''){
        setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }
      console.log((!checked))
    };
  
    const changeHandler2 = value => {
        setValue2(value)
        setPays(getName(value))
        if(value.value !== '' && phone !=='' && rue !=='' && codepostale !=='' && ville !=='' && numRue !=='' && checked === true ){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }
       //console.log(getName(value.value))
      }
      
      const ChangeVille = (event) => {
        setVille(event)
        if(event !== '' && phone !=='' && rue !=='' && codepostale !=='' && Pays !=='' && numRue !=='' && checked === true){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }
       console.log(event)
      };


      const ChangeCodepostale = (event) => {
        data.length = 0
         axios.get(`${URL_ville}${event}`).then((response) =>{
             response.data.cities.map((item, index) => {
               data.push({ key: index++,"label":item.city})
              // setData([...data,{ key: index++,"label":item.city}])
               console.log("data",data)
             })
            
              })
             .catch(function (error) {
               // handle error
               console.log(error);
             })
             

        setCodepostale(event)
        if(event !== '' && phone !=='' && rue !=='' && ville !=='' && Pays !=='' && numRue !=='' && checked === true){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }
       console.log(event)
      };
      
      const ChangeCompAd = (event) => {
        setcompAd(event)
       console.log(event)
      };
      
      const ChangePhone = (event) => {
        setPhone(event)
        if(event !== '' && codepostale !=='' && rue !=='' && ville !=='' && Pays !=='' && numRue !=='' && checked === true){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }
       console.log(event)
      };
      
      const ChangeRue = (event) => {
        setRue(event)
        if(event !== '' && phone !=='' && codepostale !=='' && ville !=='' && Pays !=='' && numRue !=='' && checked === true){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }
       console.log(event)
      };
      
      const ChangenumRue = (event) => {
        setnumRue(event)
        if(event !== '' && phone !=='' && rue !=='' && ville !=='' && Pays !=='' && codepostale !=='' && checked === true){
          setdisabledcolor2('#febf01')
          setdisabled2(true)
        } else {
          setdisabledcolor2('#DCDCDC')
          setdisabled2(false)
        }
       console.log(event)
      };
      
      const ChangeDate = (event) => {
        const moonLanding = new Date(event);
        setStartDate(event)
        setBDate((moonLanding.getDate()+"/"+(moonLanding.getMonth()+1)+"/"+moonLanding.getFullYear()))
        if(event !== '' && Nom !=='' && sexe !=='' && Prenom !=='' && value !=='' && Mail !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };
      
      const changeHandler = value => {
        setValue(value)
        setNational(getName(value))
        if(value.value !== '' && Nom !=='' && startDate !=='' && Prenom !=='' && sexe !=='' && Mail !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
        //console.log(getName(value.value))
      }
      const ChangeGenre = (event) => {
        setSexe(event)
        if(event !== '' && Nom !=='' && startDate !=='' && Prenom !=='' && value !=='' && Mail !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };
      
      const ChangeNom = (event) => {
        setNom(event)
        if(event !== '' && Prenom !=='' && startDate !=='' && sexe !=='' && value !=='' && Mail !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };
      
      const ChangePrenom = (event) => {
        setPrenom(event)
        if(event !== '' && Nom !=='' && startDate !=='' && sexe !=='' && value !=='' && Mail !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };
      
      const ChangeMail = (event) => {
        setMail(event)
        if(event !== '' && Nom !==''  && Prenom !=='' && startDate !=='' && sexe !=='' && value !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };
      
      
      function validCommand() {
        //setShow(false);
        var position = Mail.indexOf("@");
  var reste = Mail.substr(-position);
  var posit = reste.indexOf(".");
  //alert(posit)
  if(position !== -1 && posit !== -1 ){
        if(disabled){
        setShow1(true);
        }else{
          alert('Veuillez remplir tous les champs, Merci!')
        }
        console.log(Nom, Prenom, sexe, value, startDate)
      }else{
        alert('Email non valide')
      }
      }

      function validCommand1() {
        if(disabled2){
          setShow(false);
          setShow1(false);
          
          fetch(`${URL}json_get_react_register_site.php`, {
            method: 'post',
            headers: {
              'Accept': 'application/json',  // It can be used to overcome cors errors
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({name:Nom,
                                  surname:Prenom,
                                  genre:sexe,
                                  pays:Pays,
                                  bday:BDate,
                                  mail:Mail,
                                  country:value2,
                                  phone:phone,
                                  numrue:numRue,
                                  rue:rue,
                                  postal:codepostale,
                                  ville:ville,
                                  adressup:compAd,
                                  national:national
                                })
          })
            .then((response) => response.json())
            .then(() => {setShow2(true)
            setNom('')
            setPrenom('')
            setMail('')
            setSexe('')
            setValue('FR')
            setStartDate('')
            setPhone('')
            setnumRue('')
            setRue('')
            setcompAd('')
            setCodepostale('')
            setVille('')
            setPays('')
            setValue2('FR')
          })
            .catch((error) => console.error(error))
            
          }else{
            alert('Veuillez remplir tous les champs, Merci!')
          }
       
        console.log(Nom, Prenom, sexe, value, startDate, phone, numRue, rue, compAd, codepostale, ville, Pays )
        
      }
      
      function validCommand2() {
        setShow(true);
        setShow1(false);
      }

      function validCommand3() {
       
        setShow2(false);
      }


    return (
        <>
            <Menu sendColorbg={(data)=>setcolorbg(data)} sendColor={(data)=>setcolor(data)} ActiveRef={Active} ChangeActive={(data)=>setActive(data)}/>
            {/* <Redirect path="/" exact render={props => <Home show={show} setShow={()=>setShow(true)} ChangeActive={(data)=>setActive(data)} props={props}/>}/> */}
            <Route path="/" exact render={props => <Home show={show} setShow={()=>setShow(true)} ChangeActive={(data)=>setActive(data)} props={props}/>}/>
            <Route path="/faq" exact render={props => <FAQ props={props}/>}/>
            <Route path="/mention" exact render={props => <Mention  props={props} ChangeActive={(data)=>setActive(data)} sendColorbg={e => setcolorbg(e)} sendColor={e => setcolor(e)}/>}/>
            <Route path="/blog" exact render={props => <Blog setShow={()=>setShow(true)} props={props}/>}/>
            <Route path="/contact" exact render={props => <Contact props={props}/>}/>
            <Route path="/PPDP" exact render={props => <PPDP props={props} ChangeActive={(data)=>setActive(data)}/>}/>
            <Route path="/etude" exact render={props => <Etude props={props} ChangeActive={(data)=>setActive(data)}/>}/>
            {/* <Route path="/pro" exact render={props => <Pro props={props}/>}/>
            <Route path="/faqpro" exact render={props => <FAQPro props={props}/>}/>
            <Route path="/contactpro" exact render={props => <ContactPro props={props}/>}/>
            <Route path="/connexion" exact render={props => <Connexion props={props}/>}/> */}
            <Footer colorbg={colorbg} color={color} ChangeActive={(data)=>setActive(data)} sendColorbg={(data)=>setcolorbg(data)} sendColor={(data)=>setcolor(data)}/>
                <CookieConsent
            location="bottom"
            // buttonText="Sure man!!"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ display:'none' }}
            expires={150}
            acceptOnScroll="true"
            acceptOnScrollPercentage="25"
            >
            Oui, nous utilisons des cookies. Si vous poursuivez votre navigation sur notre site, ils seront automatiquement acceptés !
            </CookieConsent>
            <Modal1 show={show} disabledcolor={disabledcolor} Quit={() => setShow(false)} Nom={Nom} ChangeNom={e =>ChangeNom(e.target.value)} Prenom={Prenom} ChangePrenom={e =>ChangePrenom(e.target.value)} 
                Mail={Mail} ChangeMail={e =>ChangeMail(e.target.value)} sexe={sexe} ChangeGenre={e =>ChangeGenre(e.target.value)} value={value} changeHandler={e =>changeHandler(e.target.value)} startDate={startDate} ChangeDate={e =>ChangeDate(e.target.value)}
                validCommand={validCommand}
            />
                <Modal2 show={show1} Quit={() => {setShow1(false) 
                setShow(false)}} ChangeActive={(data)=>setActive(data)} phone={phone} ChangePhone={e => ChangePhone(e.target.value)} numRue={numRue} ChangenumRue={e => ChangenumRue(e.target.value)} rue={rue} ChangeRue={e => ChangeRue(e.target.value)} compAd={compAd} ChangeCompAd={e => ChangeCompAd(e.target.value)}
                codepostale={codepostale} ChangeCodepostale={e => ChangeCodepostale(e.target.value)} ville={ville} ChangeVille={e => ChangeVille(e.target.value)} validCommand1={validCommand1} validCommand2={validCommand2} disabledcolor2={disabledcolor2} value2={value2} changeHandler2={e => changeHandler2(e.target.value)}
                handleChange={handleChange} checked={checked}
            />
             <Modal3 show={show2} Quit={() => {setShow2(false)}} validCommand3={validCommand3}/>
        </>
    )
}

export default Site
