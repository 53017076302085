import React, {useState, useEffect, useRef} from 'react';
import logo from '../images/logo_useradventurePRO.png';
import { Container, Row, Col, Image, Button  } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { IoClose, IoChevronForwardOutline } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { TiThMenuOutline } from "react-icons/ti";
import {Fade, Zoom, Flip, Slide} from 'react-reveal';
import { Link , useHistory} from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap';
import $ from 'jquery';
import {Linking, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground,Alert, useWindowDimensions} from 'react-native';


const Menu = ({sendColorbg, sendColor, ActiveRef, ChangeActive}) => {

  const [newValue, setnewValue] = useState('1');
  const [click, setClick] = useState(false);
  const [bg, setbg] = useState("white");
  const [bgSwitch, setbgSwitch] = useState("#4b4b4b");
  const [fontSwitch, setfontSwitch] = useState('white');
  const handleClick = () => setClick(!click);
  const [navcolor, setnavcolor] = useState('light');
  const color1 = "#007F8B";
  const color2 = "#988EFA";
  var largeur = window.innerWidth;
  console.log('active',ActiveRef)
  const { height, width } = useWindowDimensions();
  
const ChangeBG = (color, variant)=>{
  setbg(color)
  setnavcolor(variant)
}





const closeToggle = () =>{
  $('.panel-collapse').collapse({
    toggle: false
  });
}


  return (
      <>
      <div id="topheader">
           <Navbar className="shadow"  expand="xxl" variant={navcolor} style={{padding:10, backgroundColor:bg }}>
          <Navbar.Brand href="">
          <Row>
          <LinkContainer onClick={()=>{ChangeBG("white",'light')
                sendColorbg("white")
                sendColor("black")}} style={{paddingRight:80, fontWeight:'bold',color: ActiveRef === '/' ? '#10b2c8' : 'grey' }}  exact to={{ pathname:"/", state:{ version: '1' }}}>
          <Nav.Link >
          <Col>
          <Flip top>
          <Image src={logo} style={{height:70, marginLeft: width <600 ? 0:20}} fluid alt="logoUseradventurePRO"/>
          </Flip>
           </Col>
           </Nav.Link>
                </LinkContainer>
          </Row>
          </Navbar.Brand>
          {/* <Navbar.Toggle   style={{zIndex:20, elevation:10, borderColor:'#10b2c8', backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='-6 0 45 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(16, 178, 200, 1)' stroke-width='5' stroke-linecap='round' stroke-miterlimit='10' d='M4 9h24M4 16h24M4 23h24'/%3E%3C/svg%3E")`}}/> */}
          <Navbar.Toggle style={{paddingTop:8, borderColor:'#10b2c8'}}>
          <TiThMenuOutline size={36} style={{paddingBottom: 3}} color={'#10b2c8'}/>
          </Navbar.Toggle>
          <Navbar.Collapse id="collapseDiv" className="justify-content-end">
        <Nav className="justify-content-center" onSelect={(selectedKey) => ChangeActive(selectedKey)} style={{fontSize:22}}>
                <LinkContainer onClick={()=>{ChangeBG("white",'light')
               closeToggle()
                sendColorbg("white")
                sendColor("black")}} style={{paddingRight:80, fontWeight:'bold',color: ActiveRef === '/' ? '#10b2c8' : 'grey' }}  exact to={{ pathname:"/", state:{ version: '1' }}}>
                            <Nav.Link >Accueil</Nav.Link>
                </LinkContainer>
                <LinkContainer onClick={()=>{ChangeBG("white",'light')
                 closeToggle()
                sendColorbg("white")
                sendColor("black")}} style={{paddingRight:80, fontWeight:'bold',color: ActiveRef === '/etude' ? '#10b2c8' : 'grey'}}   exact to={{ pathname:"/etude", state:{ version: '1' }}}>
                    <Nav.Link>Lancer une étude</Nav.Link>
                </LinkContainer>
                <LinkContainer  onClick={()=>{ChangeBG("white",'light')
                 closeToggle()
                sendColorbg("white")
                sendColor("black")}} style={{paddingRight:80, fontWeight:'bold',color: ActiveRef === '/blog' ? '#10b2c8' : 'grey'}}  exact to={{ pathname:"/blog", state:{ version: '1' }}}>
                            <Nav.Link>Blog</Nav.Link>
                </LinkContainer>
                  <LinkContainer onClick={()=>{ChangeBG("white",'light')
                   closeToggle()
                sendColorbg("white")
                sendColor("black")}} style={{paddingRight:80, fontWeight:'bold',color: ActiveRef === '/contact' ? '#10b2c8' : 'grey'}}   exact to={{ pathname:"/contact", state:{ version: '1' }}}>
                    <Nav.Link>Contact</Nav.Link>
                </LinkContainer>
              
                <Navbar.Text>
        <a href="https://useradventure.fr " style={{marginRight: largeur< 700 ? 0 : 20, padding:largeur< 700 ? 5 : 10, borderColor:'#10b2c8', borderWidth:2, borderStyle:'solid', color:'#10b2c8', borderRadius:5, textDecorationLine:'none'}}>Participer à des études</a>
      </Navbar.Text>

              </Nav> 
             
          </Navbar.Collapse>
        
      </Navbar>
      </div>
      </>
  )
}

export default Menu


