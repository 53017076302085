import React, {useState, useMemo, useEffect} from 'react';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, Button, FormControl  } from 'react-bootstrap';
import {Linking, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground,Alert} from 'react-native';
import Jump from 'react-reveal/Jump';
import Tada from 'react-reveal/Tada';
import {Flip} from 'react-reveal';
import logo from '../images/logo_useradventure.jpg';
import countryList from 'react-select-country-list'


const ModalNumber = ({show, Quit, number1, Valid}) => {

  const color1 = "#ff8500";
  const color2 = "#fe9523";
  const color3 = "#fcfcfc";
  const color5 = "#0b7482";
  const color4 = "#10b2c8";
  const [Montant, setMontant] = useState(number1);

    return (
        <>
        <Modal style={{marginTop: 100}} show={show}  onHide={Quit}>
        <Modal.Header style={{background: `linear-gradient(to bottom,  ${color1} 0%,${color2} 65%,${color3} 100%)`}} closeButton closeVariant="white">
          <Modal.Title>
        <Text style={{color:'white',fontWeight:'bold', fontSize:22, marginHorizontal:20}}>Nombre souhaité de répondants :</Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{background: `white`}}>
        <Container>
          <Row>
        {/* <Text style={{fontWeight:'bold', fontSize:22, marginHorizontal:20}}>En savoir plus</Text> */}
        </Row>
        <Row>
        <FormControl 
  style={{ fontSize:20, color:'#007984', fontWeight:'bold' }}
  type='text'
  name='username' 
  placeholder='enter number' 
  defaultValue={number1}
  onChange={e=>setMontant(e.target.value)}
/>
        </Row>
        </Container>
        </Modal.Body>
        <Modal.Footer>
    <Button onClick={()=>Valid(Montant)} style={{background: `linear-gradient(to right,  ${color5} 0%,${color4} 100%)`}}>OK</Button>
  </Modal.Footer>
      </Modal>
        </>
    )
}

export default ModalNumber

