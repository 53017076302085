import React, {useState, useMemo} from 'react';
import { useHistory } from "react-router-dom";
import {Fade, Flip, Zoom, Bounce} from 'react-reveal';
import {Linking, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground,Alert} from 'react-native';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, InputGroup, FormControl, Button } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import ModalFin from '../src/components/ModalFin';
import Testuser from './Testuser';
import Questionnaire from './Questionnaire';
import Entretien from './Entretien';
import Atelier from './Atelier';
import Autres from './Autres';
import { IoClose, IoChevronForwardOutline } from "react-icons/io5";
import ModalSplus from '../src/components/ModalSplus';
import img1 from './images/normal_u31.png';
import img2 from './images/normal_u35.png';
import img3 from './images/normal_u39.png';
import img4 from './images/u89.png';
import img5 from './images/u45.svg';
import img6 from './images/u111.png';
import img7 from './images/u109.png';
import img8 from './images/u112.png';
import mailchimpFactory from '@mailchimp/mailchimp_transactional/src/index.js';
const mailchimp = mailchimpFactory("3MaihbLvVMRazJCeofwVyw");

const Etude = () => {

    const history = useHistory();
    const color1 = "#ff8500";
    const color2 = "#fe9523";
    const color3 = "#fcfcfc";
    const color5 = "#0b7482";
    const color4 = "#10b2c8";
    const [height, setheight]= useState(0);
    const [Recrut, setRecrut]= useState(false);
    const [Type, setType]= useState(true);
    const [show1, setShow1] = useState(false);
    const [showTestUser, setshowTestUser] = useState(false);
    const [showQuestionnaire, setshowQuestionnaire] = useState(false);
    const [showEntretien, setshowEntretien] = useState(false);
    const [showAtelier, setshowAtelier] = useState(false);
    const [showAutre, setshowAutre] = useState(false);
    const [showModal, setshowModal] = useState(false);
    const [navcolor, setnavcolor] = useState('light');
    const [bg, setbg] = useState("white");
    const contact1 = 'julien.aldegheri@useconcept.com'
    const contact2 = 'maxime.aldegheri@useradventure.fr'

    // const Lancer = () => {
    //   toggle()
    //   setRecrut(false)
    //   setshowQuestionnaire(false)
    //   setshowTestUser(false)
    //   setshowEntretien(false)
    //   setshowAtelier(false)
    //   setshowAutre(false)
    //   setType(true)}
      
    //const toggle = () => {}
  
  
    const ClicTestUser = () => {
      window.location.href="#section1"
      setshowTestUser(true)
      setshowQuestionnaire(false)
      setshowAtelier(false)
      setshowEntretien(false)
      setshowAutre(false)
    }
  
    const ClicQuestionnaire = () => {
      window.location.href="#section1"
      setshowQuestionnaire(true)
      setshowTestUser(false)
      setshowAtelier(false)
      setshowEntretien(false)
      setshowAutre(false)
    }
  
    const ClicEntretien = () => {
      window.location.href="#section1"
      setshowQuestionnaire(false)
      setshowTestUser(false)
      setshowAtelier(false)
      setshowEntretien(true)
      setshowAutre(false)
    }
  
    const ClicAtelier = () => {
      window.location.href="#section1"
      setshowQuestionnaire(false)
      setshowTestUser(false)
      setshowEntretien(false)
      setshowAtelier(true)
      setshowAutre(false)
    }
  
    const ClicRU = () => {
      setRecrut(false)
      setType(false)
      setshowQuestionnaire(false)
      setshowTestUser(false)
      setshowEntretien(false)
      setshowAtelier(false)
      setshowAutre(false)
      recute()
     
      
    }
  
    const ClicBOX = () => {
      // setRecrut(false)
      // setType(false)
      // setshowQuestionnaire(false)
      // setshowTestUser(false)
      // setshowEntretien(false)
      // setshowAtelier(false)
      // setshowAutre(true)
      
      history.push("/contact");
    }
  
    const retour = () => {
      setRecrut(false)
      setType(true)
      setshowQuestionnaire(false)
      setshowTestUser(false)
      setshowEntretien(false)
      setshowAtelier(false)
      setshowAutre(false)
      //toggle()
      
    }

    const recute = () => {
      setRecrut(true)
      setType(false)
      setshowQuestionnaire(false)
      setshowTestUser(false)
      setshowAtelier(false)
      setshowEntretien(false)
      setshowAutre(false)
      
    }
   

    return (
      <>
     <View style={{flexDirection: "row", alignItems: 'center', justifyContent:'space-between', background: `linear-gradient(to right,  ${color5} 0%,${color4} 100%)`, paddingHorizontal:40,paddingVertical:15}} >
    <Text><Text onClick={retour} style={{color:'white', fontWeight:'bold', fontSize: 20, cursor: !Type ? 'pointer': undefined , textDecorationLine: !Type ? 'underline': undefined }}>LANCER UNE ÉTUDE </Text>{!Type && <><IoChevronForwardOutline size={30} style={{paddingBottom: 3}} color={'white'}/><Text style={{color:'white', fontWeight:'bold', fontSize: 20,textDecorationLine: 'none'}}> Recruter des participants</Text></>}</Text>
   
    </View>
      <Container style={{marginTop:50, marginBottom:50}}   fluid>
      
{ Type &&<> <Fade bottom>
  <Col className="text-start"  style={{fontSize: 24,color: '#5b5b5b',paddingInline:30, fontWeight:'bold'}}>Que pouvons nous faire pour vous ?</Col>
  </Fade>
 <Row className="justify-content-md-start justify-content-center"  style={{ padding:40}}>
<Col md="3" onClick={recute} className="shadow rounded" style={{cursor:'pointer', justifyContent:'center',alignItems:'center',height:220,width:220,margin:15}}>
<Row className="justify-content-center">
  <Image src={img1}  style={{height:140,width:140, padding:20}}/>
  <p style={{paddingInline:40, color:'#10b2c8', fontWeight:'bold', fontSize: 14}} class="text-center">RECRUTER DES PARTICIPANTS</p>
  </Row>
</Col>
{/* <Col md="3"  className="shadow rounded align-self-center" style={{cursor:'pointer',justifyContent:'center',alignContent:'center',height:220,width:220,margin:15}} >
<Row className="justify-content-center">
  <Image src={img4}  style={{height:140,width:140, padding:20}}/>
  <p style={{paddingInline:40, color:'#10b2c8', fontWeight:'bold', fontSize: 14}} class="text-center">CREER ET DIFFUSER UN QUESTIONNAIRE</p>
  </Row>
</Col> */}
<Col md="3" onClick={ClicBOX} className="shadow rounded" style={{cursor:'pointer',justifyContent:'center',alignItems:'center',height:220,width:220,margin:15}} >
<Row className="justify-content-center">
  <Image src={img2}  style={{height:140,width:140, padding:20}}/>
  <p style={{paddingInline:20, color:'#10b2c8', fontWeight:'bold', fontSize: 14}} class="text-center">AUTRES ETUDES ? CONTACTEZ-NOUS</p>
  </Row>
</Col>
</Row>
<Col md={{ span: 6, offset: 3 }} style={{ padding:40}}>
<Row className="justify-content-center" style={{justifyContent:'center',alignItems:'center'}} >
<Image src={img5}  style={{height:170,width:170, padding:0}}/>
<Col className="bg-light text-center align-middle borderG" style={{padding:30, fontSize: 14}} class="text-center">Vous pourrez bientôt accéder ici aux outils et facilités de la Studies’Box : tests utilisateurs, tests produits, focus group, tri de cartes et toutes les fonctionnalités d’étude dont vous rêvez directement connectées à vos utilisateurs et consommateurs ! 
<br/><br/>Le café, c'est peut être la seule chose qu'on ne fera pas...</Col>
</Row>
</Col>
</>}
{Recrut && 
<Zoom>
<Col className="text-start"  style={{fontSize: 24,color: '#5b5b5b',paddingInline:30, fontWeight:'bold'}}>Pour quel type d’étude souhaitez-vous recruter des participants ?</Col>
<Row className="justify-content-center"  style={{ padding:40}}>
<Col md="3" onClick={ClicTestUser} className="box2 rounded" style={{cursor:'pointer', justifyContent:'center',alignItems:'center',height:120,width:220,margin:15}}>
<Row className="justify-content-center">
  <Image src={img6}  style={{height:90,width:135, padding:20}}/>
  <p style={{paddingInline:40, color:'#10b2c8', fontWeight:'bold', fontSize: 14}} class="text-center">Test utilisateur</p>
  </Row>
</Col>
<Col md="3" onClick={ClicQuestionnaire} className="box2 rounded" style={{cursor:'pointer',justifyContent:'center',alignContent:'center',height:120,width:220,margin:15}} >
<Row className="justify-content-center">
  <Image src={img4}  style={{height:90,width:90, padding:20}}/>
  <p style={{paddingInline:40, color:'#10b2c8', fontWeight:'bold', fontSize: 14}} class="text-center">Questionnaire</p>
  </Row>
</Col>
<Col md="3" onClick={ClicEntretien} className="box2 rounded" style={{cursor:'pointer',justifyContent:'center',alignItems:'center',height:120,width:220,margin:15}} >
<Row className="justify-content-center">
  <Image src={img7}  style={{height:90,width:90, padding:20}}/>
  <p style={{paddingInline:20, color:'#10b2c8', fontWeight:'bold', fontSize: 14}} class="text-center">Entretien</p>
  </Row>
</Col>
<Col md="3" onClick={ClicAtelier} className="box2 rounded" style={{cursor:'pointer',justifyContent:'center',alignItems:'center',height:120,width:220,margin:15}} >
<Row className="justify-content-center">
  <Image src={img8}  style={{height:90,width:90, padding:20}}/>
  <p style={{paddingInline:20, color:'#10b2c8', fontWeight:'bold', fontSize: 14}} class="text-center">Focus group / atelier</p>
  </Row>
</Col>
</Row>
</Zoom>}
<div id="section1"></div>
<Testuser show={showTestUser} contact1={contact1} contact2={contact2} /> 
<Questionnaire show={showQuestionnaire} contact1={contact1} contact2={contact2} /> 
<Entretien show={showEntretien} contact1={contact1} contact2={contact2} />
<Atelier show={showAtelier} contact1={contact1} contact2={contact2} />
<Autres show={showAutre} contact1={contact1} contact2={contact2} />
<ModalSplus show={show1} Quit={() => {setShow1(false)}} />
<ModalFin show={showModal} Quit={() => {setshowModal(false); window.location.reload()}} />
</Container>

</>
 
    )
}

export default Etude
