import React from 'react'
import {Fade, Flip, Slide} from 'react-reveal';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup  } from 'react-bootstrap';
import Wave from 'react-wavify'

const FAQ = () => {
    return (
        <div>
        
        <Container style={{marginTop:80}}   fluid>
      <Fade bottom> 
      <Col className="text-center" style={{fontSize: 30,color: '#007F8B',paddingInline:30, fontWeight:'bold'}}>Foire aux questions</Col>
      </Fade>
      <Fade bottom> 
      <Col className="text-center" style={{fontSize: 20,color: '#007F8B',paddingInline:30, marginBlock:20}}>Vous ne trouvez pas la réponse ? Faites le nous savoir en envoyant un message directement sur notre boite mail</Col>
      </Fade>
      <Fade bottom> 
      <Col className="text-center" style={{fontSize: 20,color: '#007F8B',paddingInline:30, fontWeight:'bold'}}>Contactez-nous</Col>
      </Fade>
    </Container>
        <Container fluid style={{backgroundColor:"white", paddingBottom : 30}}>
        <Row className="justify-content-md-center" style={{marginTop:40}}>
    <Col md="10">
    <Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header><span style={{color: '#007F8B', fontWeight:'bold' }}>Comment m'inscrire ?</span></Accordion.Header>
    <Accordion.Body>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
      est laborum.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header><span style={{color: '#007F8B', fontWeight:'bold' }}>Comment m'inscrire ?</span></Accordion.Header>
    <Accordion.Body>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
      est laborum.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="2">
    <Accordion.Header><span style={{color: '#007F8B', fontWeight:'bold' }}>Comment m'inscrire ?</span></Accordion.Header>
    <Accordion.Body>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
      est laborum.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="3">
    <Accordion.Header><span style={{color: '#007F8B', fontWeight:'bold' }}>Comment m'inscrire ?</span></Accordion.Header>
    <Accordion.Body>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
      est laborum.
    </Accordion.Body>
  </Accordion.Item>
</Accordion>
</Col>
</Row>
        </Container>
        <Wave fill='#007F8B' style={{paddingInline:0, marginInline:0}} 
        paused={false}
        options={{
          height: 40,
          amplitude: 70,
          speed: 0.2,
          points: 2
        }}
  />
        </div>
    )
}

export default FAQ
